var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"470d3114d9d820cf4850cc6c7af906e34ed0b48e"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import { BrowserTracing } from '@sentry/tracing'

import awsExports from './src/aws-exports'

Sentry.init({
  dsn:
    process.env.NEXT_PUBLIC_SENTRY_DSN ||
    'https://c3b0a70b0642462889bf21f41cc496c5@o923664.ingest.sentry.io/5877061',
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.5,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  normalizeDepth: 12,
  integrations: [
    new BrowserTracing({
      tracingOrigins: [
        awsExports.aws_appsync_graphqlEndpoint
          .replace('https://', '')
          .replace('/graphql', ''),
      ],
    }),
  ],
})
