export const TRACKING_ENTITY = {
  STUDY_FINANCING: 'studienfinanzierung',
};

export const TRACKING_ACTION = {
  REGISTRATION: {
    START: 'click:los',
    CHECKED_EU_YES: 'click:EU_ja',
    CHECKED_EU_NO: 'click:EU_nein',
    MAJOR_YES: 'click:Auswahl_Ja',
    MAJOR_NO: 'click:Auswahl_Nein',
    STUDIES_CITY: 'click:Studienort',
    HIGH_SCHOOL: 'click:Hochschule',
    DEGREE: 'click:Abschluss',
    PREVIOUS_STUDIES: 'click:Studium',
    PREVIOUS_EDUCATION: 'click:Schulabschluss',
    REGISTER: 'click:Registrierung',
    CONFIRM_REGISTRATION: 'click:Bestätigung',
  },
  DETAILS_FINANCING: {
    FIRST_REGISTRATION: 'click:Anmeldung_Erste',
    FIRST_LOGOUT: 'click:Abmeldung_Erste',
    TUITION_YES: 'click:Studiengebühren_Ja',
    TUITION_NO: 'click:Studiengebühren_Nein',
    TUITION_AMOUNT: 'click:Studiengebühren_Höhe',
    ONE_TIME_PAYMENT: 'click:Einmalzahlung_Verwendung_Zeitpunkt_Höhe',
    MONTHLY_PAYMENT: 'click:Monatliche_Zahlungen_Verwendung_Zeitpunkt_Höhe',
    CONFIRM_FINANCING: 'click:Wunschfinanzierung_bestätigen',
  },
  DETAILS_PERSONAL: {
    START: 'click:Start_persönliche_Informationen',
    ADDRESS: 'click:Adressdaten',
    GRADES: 'click:Vorstudium',
  },
  DETAILS_DOCUMENTS: {
    UPLOAD_LATER_START: 'click:Dokumente_später_hochladen(abmelden)',
    UPLOAD_LATER_FINISH: 'click:Dokumentenupload_später_weitermachen(abmelden)',
    UPLOAD_START: 'click:Start_Dokumentenupload',
    UPLOAD_FINISH: 'click:Dokumentenupload_Abschließen',
  },
  DETAILS_SUMMARY: {
    CONFIRM: 'click:Nationalität_Geburtsland',
    PRIVACY_POLICY_ACCEPT: 'click:Datenschutzbestimmungen',
    FINISH: 'click:Final_Fertig_Abmelden',
  },
  OFFER: {
    CHANGE_OFFER: 'click:Finanzierungsantrag_ändern',
    CHANGE_OFFER_CONFIRM: 'click:Bestätigung_Wunschfinanzierungsänderung',
    LOGOUT_AFTER_CHANGE_OFFER: 'click:Abmelden_nach_Änderungsantrag',
    REJECT_OFFER: 'click:Ablehnung_Angebot',
    REJECT_OFFER_REASON: 'click:Ablehnungsgrund_Angebot_abgelehnt',
    ACCEPT_OFFER: 'click:Zum_Vertragsabschluss',
    CONTRACT_DOWNLOAD_BEFORE_BANK_DETAILS:
      'click:Vertrag_herunterladen_vor_Bankdetails',
    TO_BANK_DETAILS: 'click:Zu_Bankdetails',
    CONFIRM_BANK_DETAILS: 'click:Eingabe_Bankdetails',
    ID_NOW_START: 'click:IDNow_starten',
  },
  VIDEO: {
    APPLICATION_VIDEO_STARTED: 'application_video_started',
    APPLICATION_VIDEO_ENDED: 'application_video_ended',
  },
};
