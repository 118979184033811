import styled from 'styled-components';

import theme from '$/assets/theme';

export const TextBox = styled.div`
  position: absolute;
  width: 100%;
  background: ${theme.colors.white};
  z-index: 9999;
  bottom: 0;
  div.content {
    padding: ${theme.spacing.spacingM};

    p {
      max-width: 70%;
      float: left;
      margin-bottom: ${theme.spacing.spacingM};

      @media (max-width: ${theme.breakpoints.lg}) {
        max-width: 100%;
      }
    }

    div.button-container {
      float: right;
      max-width: 30%;

      @media (max-width: ${theme.breakpoints.lg}) {
        max-width: 100%;
        float: none;
      }
    }
  }
`;
