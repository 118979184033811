export type TColor = keyof typeof colors;

const colors = {
  black: '#000000',
  white: '#ffffff',
  grey0: '#fafafa',
  grey1: '#f1f1f0',
  grey2: '#dddddc',
  grey3: '#bfbfbf',
  grey4: '#a1a1a0',
  grey5: '#838382',
  grey6: '#656564',
  grey7: '#474746',
  grey8: '#292928',
  brandGreen: '#5fad36',
  brandGreen75: '#72c259',
  brandGreen50: '#9fd18d',
  brandGreen25: '#c9e1c0',
  brandGreen10: '#eff6ea',
  brandBlue: '#397880',
  brandBlue75: '#6b9aa0',
  brandBlue50: '#9ab8be',
  brandBlue25: '#cddddf',
  brandBlue10: '#ebf1f2',
  strawberry: '#fa3435',
  strawberry75: '#fc6768',
  strawberry50: '#fc999a',
  strawberry25: '#fecccc',
  strawberry10: '#feeaea',
  strawberryDark: '#ad2d2d',
  purple: '#a966fa',
  purpleDark: '#713ead',
  purple75: '#bf8dfc',
  purple50: '#d4b2fc',
  purple25: '#e9d9fe',
  purple10: '#f6effe',
  mustard: '#e6e44b',
  mustardDark: '#adac2d',
  mustard75: '#edeb78',
  mustard50: '#f2f1a5',
  mustard25: '#f9f8d2',
  mustard10: '#fcfced',
  functionalRed: '#e81d1e',
  functionalRedHover: '#A61415',
  functionalYellow: '#e8cd1d',
  functionalGreen: '#1de864',
} as const;

export default colors;
