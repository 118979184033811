import * as yup from 'yup'

export const fileInputSchema = {
  key: yup.string().required(),
  name: yup.string().required(),
}

export const yupFileInputQuestion = yup.object(fileInputSchema)

export default fileInputSchema
