import type { IToast } from './toast';

import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import Description from './components/Description';
import Icon from './components/Icon';
import Title from './components/Title';
import ToastWrapper from './components/ToastWrapper';

/**
 * @example
 * toast(
 *   <SuccessToast
 *     title='Du bist offline.'
 *     description='Bitte überprüfe deine Internetverbindung oder die Einstellungen deiner Firewall'
 *   />,
 *   { type: 'success' }
 * )
 */

const SuccessToast = (props: IToast) => (
  <ToastWrapper>
    <Icon>{props.icon}</Icon>
    <Title>{props.title}</Title>
    <Description>{props.description}</Description>
  </ToastWrapper>
);

SuccessToast.defaultProps = {
  icon: <FontAwesomeIcon icon={faCheckCircle} size={'2x'} />,
};

export default SuccessToast;
