import type {
  ElbAccountEventData,
  ElbOfferEventData,
} from '../containers/QuestionsPage/utils';

import { elb } from '@elbwalker/walker.js';

import { TRACKING_ENTITY } from '$/utils/tracking/consts';

export type ElbProps = Record<string, string>;

interface IElbValues {
  sendTrackingEvent: (
    eventEntity: string,
    eventType: string,
    data?: ElbAccountEventData | ElbOfferEventData | null,
    trigger?: string
  ) => void;
  getCampaignToken: () => string;
  elbProps: ElbProps;
}

export const getCampaignToken = (): string => {
  return window.sessionStorage.getItem('campaignToken') ?? '';
};

const useElb = (): IElbValues => {
  const campaignToken = getCampaignToken();
  const sendTrackingEvent = (
    eventModel: string,
    eventType: string,
    data?: ElbAccountEventData | ElbOfferEventData | null
  ) => {
    try {
      if (campaignToken) {
        const eventData = {
          marketing_information: campaignToken,
          ...data,
        };
        // send event
        _sendElbEvent(eventModel, eventType, eventData);
      } else {
        console.info('Skipping event - no token');
      }
    } catch (e) {
      console.warn('Failed sending tracking event', e);
    }
  };

  const elbProps: Record<string, string> = {
    'data-elb': TRACKING_ENTITY.STUDY_FINANCING,
  };
  elbProps[`data-elb-${TRACKING_ENTITY.STUDY_FINANCING}`] =
    `marketing_information:${campaignToken}`;

  return {
    sendTrackingEvent,
    getCampaignToken,
    elbProps,
  };
};

const _sendElbEvent = (
  eventEntity: string,
  eventType: string,
  eventData: Partial<ElbAccountEventData> | Partial<ElbOfferEventData>,
  trigger = 'click'
) => {
  // convert values to strings for elbwalker
  for (const property in eventData) {
    eventData[property] = String(eventData[property]);
  }

  elb(`${eventEntity} ${eventType}`, eventData, trigger);
};

export default useElb;
