import { Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

import getRandomString from './generateRandomString';
import { exportToLocalStorage } from './localstorage';

export async function signUp(
  email: string,
  firstName?: string,
  lastName?: string
) {
  try {
    const result = await Auth.signUp({
      username: email,
      password: getRandomString(30),
      attributes: {
        given_name: firstName,
        family_name: lastName,
      },
    });

    return result;
  } catch (error) {
    console.log('error signing up:', error);
    throw error;
  }
}

export async function sendCustomChallengeToEmail(email: string) {
  const cognitoUser = await Auth.signIn(email);

  exportToLocalStorage('currentUser', {
    username: cognitoUser.username,
    session: cognitoUser.Session,
  });
}

/**
 * For the login link to work in any browser, we are using the forgot password feature
 * of cognito, and setting a random password to allow the user to log in
 * This offers a similar security level as using custom challenges, but doesn't
 * have a limitation of single browser for the user
 *
 * @param email
 */
export async function cognitoSignIn(email: string) {
  return Auth.forgotPassword(email);
}

/**
 * For the login link to work in any browser, we are using the forgot password feature
 * of cognito, and setting a random password to allow the user to log in
 * This offers a similar security level as using custom challenges, but doesn't
 * have a limitation of single browser for the user
 *
 * @param challengeCode
 * @param email
 */
export async function answerCustomChallenge(
  challengeCode: string,
  email: string
) {
  const tempPassword = uuidv4();
  await Auth.forgotPasswordSubmit(email, challengeCode, tempPassword);
  await Auth.signIn({ username: email, password: tempPassword });

  try {
    // This will throw an error if the user is not yet authenticated:
    const user = await Auth.currentAuthenticatedUser();
    user.updateAttributes(
      [
        {
          Name: 'custom:hasSignedInAlready',
          Value: '1',
        },
      ],
      (e) => {
        if (e) console.warn(e);
      }
    );
  } catch (error) {
    throw new Error(error);
  }
}

export async function signOut() {
  try {
    await Auth.signOut();
    document.cookie = `deubiloggedin=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${
      process.env.NEXT_PUBLIC_WP_APP_COOKIE_DOMAIN || 'localhost'
    }`;
  } catch (error) {
    console.error('error signing out: ', error);
  }
}

export async function isAuthenticated() {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (e) {
    throw e;
  }
}
