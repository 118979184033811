import breakpoints from './breakpoints';
import colors from './colors';
import constants from './constants';
import fonts from './fonts';
import palette from './palette';
import spacing from './spacing';
import transitions from './transitions';

const theme = {
  colors,
  spacing,
  fonts,
  palette,
  breakpoints,
  transitions,
  constants,
};

export default theme;
