import type { IToast } from './toast';
import type { TypeOptions } from 'react-toastify';

// Refactor to use Enum as a key
const toastMessages: {
  [key in TypeOptions]: { [key: string]: IToast };
} = {
  info: {},
  success: {},
  warning: {},
  error: {
    'No current user': {
      title: 'Error',
      description: 'Autorisierungsfehler',
    },
    ResourceNotFoundException: {
      title: 'Error',
      // TODO: Add german description
      description: 'ResourceNotFoundException',
    },
    Unknown: {
      title: 'Error',
      description:
        'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal',
    },
    NotAuthorizedException: {
      title: 'Error',
      description: 'Link abgelaufen, bitte versuche es noch einmal',
    },
    UsernameExistsException: {
      title: 'Error',
      description: 'Ein Konto mit der angegebenen E-Mail existiert bereits',
    },
    WrongUploadedFile: {
      title: 'Error',
      description:
        'Bitte laden Sie eine .jpg, .png, .pdf, .doc, .docx Datei hoch, die kleiner als 20 MB ist',
    },
    InvalidDataProvided: {
      title: 'Error',
      description:
        'Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut oder kontaktieren Sie uns',
    },
    ThisOperationCannotBePerformed: {
      title: 'Error',
      description: 'Dieser Vorgang kann nicht ausgeführt werden',
    },
  },
  default: {},
  dark: {},
};

export default toastMessages;
