import { createGlobalStyle } from 'styled-components';

import reactDatepicker from './react-datepicker';
import resetStyle from './resetStyle';

const GlobalStyle = createGlobalStyle`
  ${resetStyle};

  html {
    overflow-y: scroll;
  }

  body {
    font-family: 'Rawline', sans-serif;
    font-size: 16px;
    line-height: 1.2;
    color: ${(props: { theme: { [key: string]: any } }) =>
      props.theme.colors.mineShaft};
    overflow-x: hidden;
    font-weight: 400;
  }

  b, strong {
    font-weight: 600;
  }

  @media (min-width: 1600px) {
    html {
      font-size: 18px;
    }
  }

  ${reactDatepicker};
`;

export default GlobalStyle;
