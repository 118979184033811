import type { ICurrentUserValues } from '$/hooks/useCurrentUser';
import type { TFileInputObject } from '$/types/components/questions';

import * as yup from 'yup';

import { MoneyUsage } from '$/components/elements/Questions/lists/moneyUsageList';
import { yupFileInputQuestion } from '$/yup/schemas/fileInputSchema';
import { responsiveFileInputSchema } from '$/yup/schemas/responsiveFileInputSchema';

export enum DocumentUploadFields {
  CERTIFICATE_OF_ENROLLMENT = 'certificateOfEnrollment', // Immatrikulationsbescheinigung
  PREVIOUS_PROOF_OF_ACHIEVEMENT = 'previousProofOfAchievement', // Bisherige Leistungsnachweise
  GRADUATION_CERTIFICATE = 'abiturCertificate', // Abizeugnis
  RESUME = 'resume', // Lebenslauf
  DIPLOMA = 'diploma', // Abschlusszeugnis
  OTHER_DOCUMENTS = 'otherDocuments', // Sonstiges
  ACCEPTANCE_FOR_A_SEMESTER_ABROAD = 'acceptanceForASemesterAbroad', // Zusage Auslandssemester
  PROOF_OF_TUITION_FEES = 'proofOfTuitionFees', // Nachweis Studiengebühren
  OTHER_ADDITIONAL_DOCUMENTS = 'otherAdditionalDocument',
}

export type DocumentsSchemaType = {
  [DocumentUploadFields.CERTIFICATE_OF_ENROLLMENT]?: TFileInputObject | null;
  [DocumentUploadFields.PREVIOUS_PROOF_OF_ACHIEVEMENT]?: TFileInputObject | null;
  [DocumentUploadFields.GRADUATION_CERTIFICATE]?: {
    id?: string;
    file: TFileInputObject;
  }[];
  [DocumentUploadFields.RESUME]?: TFileInputObject | null;
  [DocumentUploadFields.DIPLOMA]?: TFileInputObject | null;
  [DocumentUploadFields.OTHER_DOCUMENTS]?: TFileInputObject | null;
  [DocumentUploadFields.ACCEPTANCE_FOR_A_SEMESTER_ABROAD]?: TFileInputObject | null;
  [DocumentUploadFields.PROOF_OF_TUITION_FEES]?: TFileInputObject | null;
};

export const userFinishedPreviousStudies = (
  userData: ICurrentUserValues['userData']
): boolean => {
  return Boolean(
    userData?.previousStudies && userData?.previousStudiesDegree !== null
  );
};

export const userHadAnyPreviousStudies = (
  userData: ICurrentUserValues['userData']
): boolean => {
  return ![0, 1].includes(userData?.currentSemesterNumber as number);
};

export const isMoneyUsageSemesterAbroadSelected = (
  userData: ICurrentUserValues['userData']
): boolean => {
  return Boolean(
    userData?.financingPackage?.items
      ?.map((financingPackageItem) => financingPackageItem?.moneyUsage)
      .some(
        (financingPackageItem) =>
          financingPackageItem?.includes(MoneyUsage.SEMESTER_ABROAD)
      )
  );
};

export const documentsSchema: (
  userData: ICurrentUserValues['userData']
) => yup.Schema<DocumentsSchemaType> = (userData) => {
  const _userHadAnyPreviousStudies = userHadAnyPreviousStudies(userData);
  const _userFinishedPreviousStudies = userFinishedPreviousStudies(userData);
  const _isMoneyUsageSemesterAbroadSelected =
    isMoneyUsageSemesterAbroadSelected(userData);

  return yup.object({
    [DocumentUploadFields.CERTIFICATE_OF_ENROLLMENT]:
      yupFileInputQuestion.required(),
    [DocumentUploadFields.PREVIOUS_PROOF_OF_ACHIEVEMENT]: yup
      .mixed()
      .when([], () =>
        _userHadAnyPreviousStudies
          ? yupFileInputQuestion.required()
          : yup.mixed().nullable().default(null).optional()
      )
      .required(),
    [DocumentUploadFields.GRADUATION_CERTIFICATE]: responsiveFileInputSchema
      .required()
      .min(1),
    [DocumentUploadFields.RESUME]: yupFileInputQuestion.required(),
    [DocumentUploadFields.DIPLOMA]: yup
      .mixed()
      .when([], () =>
        _userFinishedPreviousStudies
          ? yupFileInputQuestion.nullable().default(null).required()
          : yup.mixed().optional()
      ),
    [DocumentUploadFields.OTHER_DOCUMENTS]: yupFileInputQuestion
      .nullable()
      .default(null)
      .optional(),
    [DocumentUploadFields.ACCEPTANCE_FOR_A_SEMESTER_ABROAD]: yup
      .mixed()
      .when([], () =>
        _isMoneyUsageSemesterAbroadSelected
          ? yupFileInputQuestion.required()
          : yup.mixed().nullable().default(null).optional()
      )
      .required(),
    [DocumentUploadFields.PROOF_OF_TUITION_FEES]: yup
      .mixed()
      .when([], () =>
        _isMoneyUsageSemesterAbroadSelected
          ? yupFileInputQuestion.required()
          : yup.mixed().nullable().default(null).optional()
      )
      .required(),
  });
};
