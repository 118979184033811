import type { UserData } from '$/API';
import type { ICurrentUserContext } from '$/providers/CurrentUserProvider';

import add from 'date-fns/add';
import isPast from 'date-fns/isPast';
import { useContext } from 'react';

import { CurrentUserContext } from '$/providers/CurrentUserProvider';
import { YearUpdateStatus } from '$/types/enums/YearlyUpdateStatus';

export interface ICurrentUserValues extends ICurrentUserContext {
  userData: UserData | undefined | null;
  enteredRepayments: boolean;
  areStudiesStarted: boolean;
  yearlyUpdatePending: { year: number; IdStatus: number | null }[];
}

const useCurrentUser = (): ICurrentUserValues => {
  const context = useContext(CurrentUserContext);
  const userData = context.userQuery?.data?.getUserData as UserData | undefined;

  const enteredRepayments =
    !userData?.endDate ||
    isPast(add(new Date(userData.endDate), { months: 6 }));

  const areStudiesStarted = !!(
    userData?.startDate && isPast(new Date(userData?.startDate))
  );

  const yearlyUpdatePending =
    userData
      ?.yearUpdate!.filter(({ IdStatus }) =>
        [
          YearUpdateStatus.NOT_STARTED,
          YearUpdateStatus.IN_PROGRESS,
          null,
        ].includes(IdStatus as number)
      )
      .filter((v) => !!v)
      .map(({ year, IdStatus }) => ({ year, IdStatus: IdStatus as number }))
      .filter(
        (value, index, array) =>
          array.findIndex(({ year }) => year === value.year) === index
      ) ?? [];

  return {
    userData,
    ...context,
    enteredRepayments,
    areStudiesStarted,
    yearlyUpdatePending,
  };
};

export default useCurrentUser;
