import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
const GTM_AUTH = process.env.NEXT_PUBLIC_GTM_AUTH;
const GTM_ENV = process.env.NEXT_PUBLIC_GTM_ENV;
const environment = process.env.NODE_ENV;
const ENV_SET = !!(GTM_AUTH && GTM_ENV && GTM_ID);

let GTM_Warning = false;

if (environment !== 'development' && !ENV_SET) {
  if (!GTM_Warning) {
    console.warn('No GTM environment set');
    GTM_Warning = true;
  }
}

// const GTM = {
//   Script: () => (
//     <>
//       <script
//         dangerouslySetInnerHTML={{
//           __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${GTM_AUTH}&gtm_preview=${GTM_ENV}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTM_ID}');`,
//         }}
//       />
//       <script
//         async
//         src={'https://www.googletagmanager.com/gtag/js?id=AW-997693312'}
//       />
//     </>
//   ),
//   NoScript: () => (
//     <noscript
//       dangerouslySetInnerHTML={{
//         __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}&gtm_auth=${GTM_AUTH}&gtm_preview=${GTM_ENV}&gtm_cookies_win=x" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
//       }}
//     />
//   ),
//   GTag: (...args: any[]) => {
//     window.dataLayer = window.dataLayer || []
//     window.dataLayer.push(args)
//   },
// }

export const useGoogleTagManager = () => {
  useEffect(() => {
    if (ENV_SET) {
      window.dataLayer = window.dataLayer || [];
      function gtag(...args) {
        window.dataLayer.push(args);
      }
      gtag('consent', 'default', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'denied',
        wait_for_update: 2000, // milliseconds to wait for update
      });
      gtag('set', 'ads_data_redaction', true);

      window.addEventListener('ucEvent', function (e) {
        if (e.detail && e.detail.event == 'consent_status') {
          // Please replace the analytics service name here with the customized service
          const ucAnalyticsService = 'Google Analytics';
          // Please replace the ad service name here with the customized service
          const ucAdService = 'Google Ads Remarketing';

          if (
            e.detail.hasOwnProperty(ucAnalyticsService) &&
            e.detail.hasOwnProperty(ucAdService)
          ) {
            gtag('consent', 'update', {
              ad_storage: e.detail[ucAdService] ? 'granted' : 'denied',
              ad_user_data: e.detail[ucAdService] ? 'granted' : 'denied',
              ad_personalization: e.detail[ucAdService] ? 'granted' : 'denied',
              analytics_storage: e.detail[ucAnalyticsService]
                ? 'granted'
                : 'denied',
            });
          } else {
            if (e.detail.hasOwnProperty(ucAdService)) {
              gtag('consent', 'update', {
                ad_storage: e.detail[ucAdService] ? 'granted' : 'denied',
                ad_user_data: e.detail[ucAdService] ? 'granted' : 'denied',
                ad_personalization: e.detail[ucAdService]
                  ? 'granted'
                  : 'denied',
              });
            }
            if (e.detail.hasOwnProperty(ucAnalyticsService)) {
              gtag('consent', 'update', {
                analytics_storage: e.detail[ucAnalyticsService]
                  ? 'granted'
                  : 'denied',
              });
            }
          }
        }
      });

      TagManager.initialize({
        gtmId: GTM_ID,
        auth: GTM_AUTH,
        preview: GTM_ENV,
      });
    }
  }, []);
};

// export default ENV_SET
//   ? GTM
//   : {
//       Script: () => null,
//       NoScript: () => null,
//       GTag: (...args: any[]) => {},
//     }
