const constants = {
  breakpointValues: {
    xs: 375,
    sm: 576,
    md: 768,
    lg: 1024,
    xl: 1200,
    xxl: 1600,
  },
  containerSizes: {
    xs: '0px',
    sm: '540px',
    md: '720px',
    lg: '960px',
    xl: '1140px',
  },
};

export default constants;
