import type { IVariantProps } from './index';

import { css } from 'styled-components';

import colors from './_colors';

const menu = (props: IVariantProps) => ({
  button: css`
    color: ${(props) => props.theme.colors.grey7};
    border-radius: 5px;
    cursor: pointer;
    ${props.selected &&
    `
      background-color: ${colors[props.color.hover]};
      color: ${colors.brandBlue};
    `}

    &:hover {
      background-color: ${colors[props.color.hover]};
      color: ${colors.brandBlue};
    }
  `,
  text: css`
    color: ${(props) => props.theme.colors.grey7};
  `,
});

export default menu;
