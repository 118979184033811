import styled from 'styled-components';

import { changeOpacityOnLoading } from './LoaderContainer';
import { includeVariant } from './variants';

import theme from '$/assets/theme';

const ButtonText = styled('span')`
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  min-height: 25px;
  overflow-wrap: break-word;
  overflow: auto;
  transition: all ${theme.transitions.normal};

  ${(props) => props.isLoading && changeOpacityOnLoading};

  ${(props: { variant: string; [key: string]: any }) =>
    includeVariant(props, 'text')};
`;

export default ButtonText;
