import type { RedirectCookie } from '$/types/custom';

const isRedirectCookieValid = (cookie: RedirectCookie): boolean => {
  // @ts-ignore
  if (!cookie.redirect) return false;

  const cookieDate = cookie.added;
  const now = new Date().getTime();

  // redirect only valid for 3 minutes
  return cookieDate + 3 * 60000 > now;
};

export default isRedirectCookieValid;
