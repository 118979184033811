import type { ICurrentUserValues } from '$/hooks/useCurrentUser';

import {
  DocumentUploadFields,
  isMoneyUsageSemesterAbroadSelected,
  userFinishedPreviousStudies,
  userHadAnyPreviousStudies,
} from '$/containers/Details/DocumentUploadPage/formConfig';

const REQUIRED_DOCUMENTS: DocumentUploadFields[] = [
  DocumentUploadFields.CERTIFICATE_OF_ENROLLMENT,
  DocumentUploadFields.PREVIOUS_PROOF_OF_ACHIEVEMENT,
  DocumentUploadFields.GRADUATION_CERTIFICATE,
  DocumentUploadFields.RESUME,
  DocumentUploadFields.DIPLOMA,
  DocumentUploadFields.ACCEPTANCE_FOR_A_SEMESTER_ABROAD,
  DocumentUploadFields.PROOF_OF_TUITION_FEES,
];

function checkUserHasAllDocuments(userData: ICurrentUserValues['userData']) {
  if (!userData) {
    return false;
  }

  const missingDocuments: DocumentUploadFields[] = [];

  REQUIRED_DOCUMENTS.forEach((requiredDocument) => {
    if (!userData.documents) return;

    const foundDocument = userData.documents.find(
      (document) => document?.field === requiredDocument
    );

    if (foundDocument) {
      return;
    }

    // These documents are missing, now we check if they are actually required
    switch (requiredDocument) {
      case DocumentUploadFields.PREVIOUS_PROOF_OF_ACHIEVEMENT: {
        if (userHadAnyPreviousStudies(userData)) {
          missingDocuments.push(requiredDocument);
        }
        break;
      }
      case DocumentUploadFields.DIPLOMA: {
        if (userFinishedPreviousStudies(userData)) {
          missingDocuments.push(requiredDocument);
        }
        break;
      }
      case DocumentUploadFields.PROOF_OF_TUITION_FEES:
      case DocumentUploadFields.ACCEPTANCE_FOR_A_SEMESTER_ABROAD: {
        if (isMoneyUsageSemesterAbroadSelected(userData)) {
          missingDocuments.push(requiredDocument);
        }
        break;
      }
      default: {
        missingDocuments.push(requiredDocument);
      }
    }
  });

  return missingDocuments.length === 0;
}

export default checkUserHasAllDocuments;
