import React from 'react';
import styled, { keyframes } from 'styled-components';

import theme from '$/assets/theme';

const spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface LoaderProps {
  loaderColor?: string;
  style?: object;
  width?: string;
  height?: string;
}

const LoaderStyle = styled('span')<LoaderProps>`
  display: inline-flex;
  width: ${(props) => (props.width ? props.width : '14px')};
  height: ${(props) => (props.height ? props.height : '14px')};
  border-radius: 50%;
  overflow: hidden;
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) => props.loaderColor};
  border-top-color: transparent !important;
  animation: ${spinner} 2s linear infinite;
`;

const Loader = ({
  loaderColor = theme.colors.black,
  ...props
}: LoaderProps) => <LoaderStyle loaderColor={loaderColor} {...props} />;

export default Loader;
