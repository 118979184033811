import type { IVariantProps } from './index';

import { css } from 'styled-components';

import colors from './_colors';

const primary = (props: IVariantProps) => ({
  button: css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    cursor: pointer;
    border-radius: 5px;
    background: ${colors[props.color.unset]};
    transition: all 0.25s;
    border: unset;

    &:hover {
      background: ${colors[props.color.hover]};
      opacity: 0.9;
    }

    &:active {
      opacity: 0.7;
    }

    ${props.disabled &&
    css`
      background: ${colors[props.color.disabled]};
      cursor: not-allowed;

      transition: none;

      &:hover {
        background: ${colors[props.color.disabled]};
        opacity: initial;
      }

      &:active {
        background: ${colors[props.color.disabled]};
        opacity: initial;
      }
    `}
  `,
  text: css`
    color: ${(props) => props.theme.colors.white};
  `,
});

export default primary;
