export type TFonts = 'rawline' | 'awesome5pro';

const fonts: {
  [index in TFonts]: string;
} = {
  rawline: "'Rawline', sans-serif",
  awesome5pro: "'FontAwesome5Pro', sans-serif",
};

export default fonts;
