import type { TQuestionAnswer } from '$/types/components/questions';

export enum ANNUAL_UPDATE_ACTIVITY {
  EMPLOYED = 1,
  FREELANCER = 2,
  UNEMPLOYED = 3,
  STUDYING = 4,
  PARENTAL_LEAVE = 5,
}

const annualUpdateActivityList: TQuestionAnswer<ANNUAL_UPDATE_ACTIVITY>[] = [
  { id: ANNUAL_UPDATE_ACTIVITY.EMPLOYED, value: 'Ich war angestellt' },
  {
    id: ANNUAL_UPDATE_ACTIVITY.FREELANCER,
    value: 'Ich habe selbstständig gearbeitet',
  },
  { id: ANNUAL_UPDATE_ACTIVITY.UNEMPLOYED, value: 'Ich war arbeitslos' },
  {
    id: ANNUAL_UPDATE_ACTIVITY.STUDYING,
    value: 'Ich war an einer Hochschule immatrikuliert',
  },
  {
    id: ANNUAL_UPDATE_ACTIVITY.PARENTAL_LEAVE,
    value: 'Ich war im Mutterschutz/ Elternzeit',
  },
];

export default annualUpdateActivityList;
