import { ANNUAL_UPDATE_ACTIVITY as YearlyUpdateItemType } from '../../src/components/elements/Questions/lists/annualUpdateActivityList'

export { YearlyUpdateItemType }

export enum YearUpdateStatus {
  NOT_STARTED = 0,
  IN_PROGRESS = 1,
  SENT = 2,
  COMPLETED,
}
