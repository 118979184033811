import type { IToast } from './toast';
import type { ToastOptions } from 'react-toastify';
import type { TypeOptions } from 'react-toastify/dist/types';

import { toast } from 'react-toastify';

import ErrorToast from './ErrorToast';
import SuccessToast from './SuccessToast';

export type IVariantToast = IToast;

/**
 * @example
 *  toast(toastContent('error', { title: 'My title', description: 'My description' }), {
    type: 'error'
  })
 */
const toastContent = (type: TypeOptions, props: IVariantToast) => {
  switch (type) {
    case 'success': {
      return <SuccessToast {...props} />;
    }
    case 'error': {
      return <ErrorToast {...props} />;
    }
    default: {
      return <ErrorToast {...props} />;
    }
  }
};

/**
 * @example
 *  successToast({ title: 'My title', description: 'My description' })
 */
const successToast = (content: IToast, options?: ToastOptions) => {
  toast(<SuccessToast {...content} />, { type: 'success', ...options });
};

/**
 * @example
 *  errorToast({ title: 'My title', description: 'My description' })
 */
const errorToast = (content: IToast, options?: ToastOptions) => {
  toast(<ErrorToast {...content} />, { type: 'error', ...options });
};

export { toastContent, errorToast, successToast };
