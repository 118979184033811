import type { IVariantProps } from './index';

import { css } from 'styled-components';

import theme from '$/assets/theme';

const answer = (props: IVariantProps) => ({
  button: css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 12px 20px;
    background: ${theme.colors.grey1};
    border: 1px solid ${theme.colors.grey1};
    outline: none;
    cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
    transition: all 0.2s;

    &:focus {
      border: 1px solid gray;
    }

    ${props.selected &&
    css`
      border-color: ${(props) => props.theme.colors.brandGreen};
      background: ${(props) => props.theme.colors.white};
    `}
  `,
  text: css`
    font-size: 18px;
    font-weight: normal;
    font-family: 'Rawline';
    color: ${props.disabled ? theme.colors.grey3 : theme.colors.grey7};
  `,
  iconContainer: css`
    position: absolute;
    left: -11px;
    margin: 0;
  `,
});

export default answer;
