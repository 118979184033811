import styled from 'styled-components';

const Title = styled('div')`
  grid-area: title;
  font-weight: bold;
  font-size: 15px;
`;

Title.defaultProps = {
  className: 'toast-title',
};

export default Title;
