import type { TQuestionAnswer } from '$/types/components/questions';

export enum MoneyUsage {
  COST_OF_LIVING = 'Lebenshaltungskosten',
  RENT = 'Miete',
  TUITION_FEES = 'Studiengebühren',
  STUDY_MATERIAL = 'Studienmaterial',
  SEMESTER_ABROAD = 'Auslandssemester',
  MOVE = 'Umzug',
  TRAVELLING_EXPENSES = 'Reisekosten',
  CUSTOM = 'Sonstiges',
}

const moneyUsageList: TQuestionAnswer<MoneyUsage>[] = [
  { id: MoneyUsage.COST_OF_LIVING, value: 'Lebenshaltungskosten' },
  { id: MoneyUsage.RENT, value: 'Miete' },
  { id: MoneyUsage.TUITION_FEES, value: 'Studiengebühren' },
  { id: MoneyUsage.STUDY_MATERIAL, value: 'Studienmaterial' },
  { id: MoneyUsage.SEMESTER_ABROAD, value: 'Auslandssemester' },
  { id: MoneyUsage.MOVE, value: 'Umzug' },
  { id: MoneyUsage.TRAVELLING_EXPENSES, value: 'Reisekosten' },
  { id: MoneyUsage.CUSTOM, value: 'Sonstiges' },
];

export default moneyUsageList;
