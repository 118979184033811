import type { GetUserDataQuery, PendingSemesterUpdate } from '$/API';
import type { NextRouter } from 'next/router';
import type { UseQueryResult } from 'react-query/types/react/types';

import * as Sentry from '@sentry/nextjs';
import { Auth } from 'aws-amplify';
import differenceInDays from 'date-fns/differenceInDays';
import startOfDay from 'date-fns/startOfDay';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import ApiClient from '$/ApiClient';
import Loader from '$/components/elements/Loader';
import { toastContent } from '$/components/elements/Toasts';
import toastMessages from '$/components/elements/Toasts/toastMessages';
import { getUserData } from '$/graphql/customQueries';
import useElb from '$/hooks/useElb';
import {
  default as DeutscheBildungPageUrl,
  default as deutscheBildungPageUrl,
} from '$/types/deutscheBildungPageUrl';
import checkUserHasAllDocuments from '$/utils/checkUserHasAllDocuments';
import {
  cognitoSignIn as CognitoSignIn,
  signOut as CognitoSignOut,
  answerCustomChallenge,
  isAuthenticated,
} from '$/utils/cognitoAuthentication';
import isRedirectCookieValid from '$/utils/isRedirectCookieValid';
import {
  importFromLocalStorage,
  removeFromLocalStorage,
} from '$/utils/localstorage';
import { TRACKING_ACTION, TRACKING_ENTITY } from '$/utils/tracking/consts';

export interface ICurrentUserContext {
  signOut: () => Promise<void>;
  signIn: (email: string) => Promise<any>;
  userQuery: UseQueryResult<GetUserDataQuery> | null;
  authenticated: boolean;
  initializing: boolean;
}

export const CurrentUserContext = createContext<ICurrentUserContext>({
  signOut: CognitoSignOut,
  signIn: CognitoSignIn,
  userQuery: null,
  authenticated: false,
  initializing: true,
});

export interface ICurrentUserProviderProps {
  children: React.ReactNode;
}

const checkAndReplace = async (
  destination: string | { pathname: string; query: null },
  router: NextRouter
): Promise<boolean | void> => {
  if (destination !== router.pathname) {
    await router.replace(destination);
  }
};

// this flag makes sure that we check the auth once
let invoked = false;

const CurrentUserProvider = ({ children }: ICurrentUserProviderProps) => {
  const router = useRouter();
  const [authenticated, setIsAuthenticated] = useState<boolean>(false);
  const [initializing, setInitializing] = useState(true);
  const [loggingOut, setLoggingOut] = useState(false);
  const queryClient = useQueryClient();
  const lDClient = useLDClient();
  const { sendTrackingEvent } = useElb();

  const pendingSemesterUpdatesQuery = useQuery<PendingSemesterUpdate[]>(
    ['pending-semester-updates'],
    async () => ApiClient.getPendingSemesterUpdates(),
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const userQuery = useQuery([getUserData], ApiClient.getUserData, {
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
    onError: async () => {
      await signOut();
      await router.push(DeutscheBildungPageUrl.SIGN_IN);
    },
  });

  useEffect(() => {
    const fn = async () => {
      if (!initializing || invoked) return;
      invoked = true;

      const impersonate = (impersonateToken: string) => {
        try {
          const {
            u: username,
            p: keyPrefix,
            i: idToken,
            a: accessToken,
            r: refreshToken,
            c: clockDrift,
          } = JSON.parse(atob(impersonateToken));
          window.localStorage.setItem(
            `${keyPrefix}.${username}.idToken`,
            idToken
          );

          window.localStorage.setItem(
            `${keyPrefix}.${username}.accessToken`,
            accessToken
          );

          window.localStorage.setItem(
            `${keyPrefix}.${username}.refreshToken`,
            refreshToken
          );

          window.localStorage.setItem(
            `${keyPrefix}.${username}.clockDrift`,
            clockDrift
          );

          window.localStorage.setItem(`${keyPrefix}.LastAuthUser`, username);

          window.localStorage.setItem(
            'amplify-authenticator-authState',
            'signedIn'
          );

          setIsAuthenticated(true);
          setInitializing(false);
        } catch (err) {
          console.log('Failed to impersonate', { err });
        }
      };

      setInitializing(true);

      let userAuthenticated = false;
      const query = new URL(window.location.href).searchParams;

      const confirmationCode = query.get('validation');
      const confirmEmail = query.get('confirmEmail');
      const email = query.get('email');

      try {
        await isAuthenticated();
        userAuthenticated = true;

        // check if redirect in the query
        const redirectUrl = query.get('redirect');

        if (redirectUrl) {
          window.location.href = `${process.env.NEXT_PUBLIC_WP_APP_HOST}${redirectUrl}`;
        }
      } catch (err) {
        // do nothing
      }

      // Impersonate token
      const impersonateToken = query.get('impr');
      if (impersonateToken) {
        impersonate(impersonateToken);
      }

      if (
        !userAuthenticated &&
        confirmationCode &&
        typeof confirmationCode === 'string' &&
        typeof email === 'string'
      ) {
        try {
          const decodedEmail = atob(email);

          if (router.query?.email) {
            delete router.query.email;
          }

          if (router.query?.confirmationCode) {
            delete router.query.confirmationCode;
          }

          await answerCustomChallenge(confirmationCode, decodedEmail);
          sendTrackingEvent(
            TRACKING_ENTITY.STUDY_FINANCING,
            TRACKING_ACTION.REGISTRATION.CONFIRM_REGISTRATION
          );
        } catch (err) {
          Sentry.captureException(err);

          console.error(err, 'Error on auth');
          let status;

          const message = toastMessages['error'][String(err.code)];
          if (!message) {
            toast(
              toastContent('error', {
                title: `Error: ${err.name}`,
                description: err?.message,
              }),
              {
                type: 'error',
                autoClose: false,
              }
            );
          } else {
            toast(toastContent('error', message), {
              type: 'error',
              autoClose: false,
            });
          }

          switch (err.message) {
            case 'Invalid session for the user.': {
              status = 'linkExpired';
              break;
            }
            default: {
              status = 'error';
              break;
            }
          }

          await checkAndReplace(
            `${DeutscheBildungPageUrl.SIGN_IN}?status=${status}`,
            router
          );

          setIsAuthenticated(false);
          setInitializing(false);
          return;
        }

        try {
          const encryptedCookie = await ApiClient.generateNewCookiePayload();

          // @ts-ignore TODO: Check type for encryptedCookie.getNewCookie
          document.cookie = `dbdeBewerbung=${encryptedCookie.getNewCookie
            ?.encodedCookiePayload};max-age=${2592000};domain=${
            process.env.NEXT_PUBLIC_WP_APP_COOKIE_DOMAIN || 'localhost'
          }${
            process.env.NEXT_PUBLIC_WP_APP_COOKIE_DOMAIN !== 'localhost'
              ? '; Secure'
              : ''
          }`;

          // check if user is coming from website redirect, if yes - redirect
          // him back to originating request
          const cookie = importFromLocalStorage('wordpress-redirect', false);

          if (cookie && isRedirectCookieValid(cookie)) {
            removeFromLocalStorage('wordpress-redirect');

            if (isRedirectCookieValid(cookie)) {
              window.location.href = `${process.env.NEXT_PUBLIC_WP_APP_HOST}${cookie.redirect}`;
              return;
            }
          }
          userAuthenticated = true;
        } catch (err) {
          Sentry.captureException(err);

          console.log('error on cookie encrypting ===', err);

          if (
            err?.errors?.[0]?.message ===
            'Key should be a string or an ArrayBuffer / Buffer'
          ) {
            userAuthenticated = true;
          } else {
            const status = 'error';

            await checkAndReplace(
              `${DeutscheBildungPageUrl.SIGN_IN}?status=${status}`,
              router
            );

            setIsAuthenticated(false);
            setInitializing(false);
            return;
          }
        }
      }

      if (userAuthenticated) {
        let redirect = true;
        const user = await userQuery.refetch();

        if (user?.data?.getUserData?.campaignToken) {
          window.sessionStorage.setItem(
            'campaignToken',
            user?.data?.getUserData?.campaignToken
          );
        }

        Sentry.setUser({
          email: user?.data?.getUserData?.email,
          id: user?.data?.getUserData?.id,
        });

        if (
          confirmationCode &&
          confirmEmail &&
          user?.data?.getUserData?.status === 14
        ) {
          await checkAndReplace('/details/email-verified', router);
          redirect = false;
        }

        if ('/signout' in query) {
          redirect = false;
        }

        if (!user || user.isError) {
          // @ts-ignore
          user.error?.errors?.map((err) => {
            toast(
              toastContent('error', {
                title: `Error: ${err.path}`,
                description: err?.message,
              }),
              {
                type: 'error',
                autoClose: false,
              }
            );
          });
          await signOut();
          redirect = false;
          return;
        }

        const flags = await lDClient?.identify({
          key: user.data?.getUserData?.id?.toString(),
          firstName: user.data?.getUserData?.firstName,
          lastName: user.data?.getUserData?.lastName,
          email: user.data?.getUserData?.email,
          anonymous: false,
        });
        // if you want to change the redirect flow based on flags, use these
        console.log('flags', flags);

        if (redirect) {
          switch (user.data?.getUserData?.status) {
            case 14: {
              if (
                // doesn't have financing
                !user.data?.getUserData?.financingPackage?.id &&
                // also never been in info
                !user.data?.getUserData?.nationalityId
              ) {
                await checkAndReplace('/details/financing', router);
              }
              // doesn't have info
              else if (!user.data?.getUserData?.nationalityId) {
                await checkAndReplace('/details/personal-infos', router);
              }
              // doesn't have documents
              else {
                if (!checkUserHasAllDocuments(userQuery.data?.getUserData)) {
                  await checkAndReplace('/details/document-upload', router);
                } else {
                  await checkAndReplace('/details/summary', router);
                }
              }
              break;
            }
            // If a customer has completed his application: 15
            case 15: {
              await checkAndReplace(
                DeutscheBildungPageUrl.WAITING_FOR_AN_OFFER,
                router
              );
              break;
            }
            // If an application in in on hold status: 16
            case 16: {
              await checkAndReplace('/no-offer/on-hold', router);
              break;
            }
            // If an application is rejected by DeuBi: 17
            case 17: {
              if (user.data.getUserData?.residenceStatus === 0) {
                await checkAndReplace('/registration/sorry', router);
              } else {
                await checkAndReplace(deutscheBildungPageUrl.NO_OFFER, router);
              }
              break;
            }
            // Internal processing by DeuBi: 18
            case 18: {
              await checkAndReplace(
                DeutscheBildungPageUrl.WAITING_FOR_AN_OFFER,
                router
              );
              break;
            }
            case 19: {
              if (user.data?.getUserData?.bic && user.data?.getUserData?.iban) {
                await checkAndReplace('/accept-offer/id-now', router);
                break;
              }
              if (
                user.data?.getUserData?.contract?.dateCreated &&
                differenceInDays(
                  startOfDay(
                    new Date(user.data?.getUserData?.contract?.dateCreated)
                  ),
                  startOfDay(new Date())
                ) >= 14
              ) {
                await checkAndReplace('/expired-offer', router);
                break;
              }
              await checkAndReplace('/offer', router);
              break;
            }
            // If DeuBI accepts the application, but needs additional documents: 20
            case 20: {
              await checkAndReplace('/offer', router);
              break;
            }
            // Consultant requested additional documents for user to upload
            case 21: {
              await checkAndReplace('/update-documents', router);
              break;
            }
            // User is turned off: 110
            case 110: {
              await checkAndReplace(DeutscheBildungPageUrl.NO_OFFER, router);
              break;
            }
            // If user rejects the application: 112
            case 112: {
              await checkAndReplace('/reject/offer-rejected', router);
              break;
            }
            // Contract sent: 113
            case 113: {
              await checkAndReplace(
                DeutscheBildungPageUrl.WAITING_FOR_AN_OFFER,
                router
              );
              break;
            }
            // Sign the contract back: 114
            case 114: {
              await checkAndReplace(
                DeutscheBildungPageUrl.WAITING_FOR_AN_OFFER,
                router
              );
              break;
            }
            // If the customer signed the contract (final state): 115
            case 115: {
              if (
                !(
                  // ignored URL patterns
                  [
                    DeutscheBildungPageUrl.USER_SETTINGS,
                    DeutscheBildungPageUrl.ADD_DOCUMENTS,
                    DeutscheBildungPageUrl.SEMESTER_UPDATE,
                    DeutscheBildungPageUrl.YEARLY_UPDATE,
                    DeutscheBildungPageUrl.VOLUNTARY_UPDATE,
                    DeutscheBildungPageUrl.CHANGE_OFFER,
                    DeutscheBildungPageUrl.CUSTOMER_PORTAL,
                    DeutscheBildungPageUrl.USER_FINANCING_OVERVIEW,
                  ].some((url) => window.location.pathname.includes(url))
                )
              ) {
                // user change offer in progress
                if (
                  typeof user.data?.getUserData?.changeOfferStatus === 'number'
                ) {
                  if (user.data?.getUserData?.changeOfferStatus === 1020) {
                    await checkAndReplace(
                      DeutscheBildungPageUrl.CHANGE_OFFER_DONE,
                      router
                    );
                    break;
                  } else if (
                    user.data?.getUserData?.changeOfferStatus === 1031
                  ) {
                    await checkAndReplace(
                      DeutscheBildungPageUrl.OFFER_PREVIEW,
                      router
                    );
                    break;
                  } else if (user.data?.getUserData?.changeOfferStatus > 1020) {
                    await checkAndReplace(
                      DeutscheBildungPageUrl.HOMEPAGE,
                      router
                    );
                    break;
                  }
                }

                const changeOfferNotification =
                  user.data?.getUserData?.changeOfferStatus &&
                  [1080, 1081, 1082].includes(
                    user.data.getUserData.changeOfferStatus
                  );

                const { data: pendingSemesterUpdates } =
                  await pendingSemesterUpdatesQuery.refetch();
                const hasSemesterUpdate =
                  pendingSemesterUpdates?.length ?? 0 > 0;

                if (hasSemesterUpdate || changeOfferNotification) {
                  await checkAndReplace(
                    DeutscheBildungPageUrl.HOMEPAGE,
                    router
                  );
                } else {
                  await checkAndReplace('/financing', router);
                }
              }
              break;
            }
            // Contract incorrect: 116
            case 116: {
              await checkAndReplace(DeutscheBildungPageUrl.HOMEPAGE, router);
              break;
            }
            // Offer expired: 117
            case 117: {
              await checkAndReplace('/expired/offer-expired', router);
              break;
            }
            // Contract refused: 118
            case 118: {
              await checkAndReplace('/reject/offer-rejected ', router);
              break;
            }
            // Contract expired: 119
            case 119: {
              await checkAndReplace('/reject/offer-rejected', router);
              break;
            }
            default: {
              // TODO
              // If the customer signed the contract (final state): 115
              await checkAndReplace('/', router);
              break;
            }
          }
        }

        setIsAuthenticated(true);
        await userQuery.refetch();
      } else {
        if (!router.pathname.startsWith('/registration')) {
          await checkAndReplace(DeutscheBildungPageUrl.SIGN_IN, router);
        }

        setIsAuthenticated(false);
      }

      setInitializing(false);
    };

    fn();
  }, [router]);

  const signOut = useCallback(async (status = null) => {
    try {
      setLoggingOut(true);
      await CognitoSignOut();
      queryClient.clear();
      localStorage.clear();
      sessionStorage.clear();

      // clear WP cookie
      document.cookie = `dbdeBewerbung=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${
        process.env.NEXT_PUBLIC_WP_APP_COOKIE_DOMAIN || 'localhost'
      }${
        process.env.NEXT_PUBLIC_WP_APP_COOKIE_DOMAIN !== 'localhost'
          ? '; Secure'
          : ''
      }`;

      await lDClient?.identify({
        anonymous: true,
      });

      setIsAuthenticated(false);
      const redirectUrl = status
        ? `${DeutscheBildungPageUrl.LOGOUT}?status=${status}`
        : DeutscheBildungPageUrl.LOGOUT;
      await router.push(redirectUrl);
    } catch (e) {
      throw new Error('Error while signing out'); // TODO: Insert error toast
    } finally {
      setLoggingOut(false);
    }
  }, []);

  const signIn = CognitoSignIn;

  useEffect(() => {
    // TODO: think of a different way to store the identity in the tb. this is definitely a bad way
    async function updateCurrentUserIdentityId() {
      const userData = userQuery.data?.getUserData;
      if (!userData || userData?.cognitoIdentity) return;

      const { identityId } = await Auth.currentCredentials();
      if (!identityId) return;

      await ApiClient.updateCognitoIdentity({ cognitoIdentity: identityId });

      await userQuery.refetch();
    }

    updateCurrentUserIdentityId();
  }, [userQuery.data?.getUserData]);

  return (
    <CurrentUserContext.Provider
      value={{
        authenticated,
        initializing,
        userQuery,
        signOut,
        signIn,
      }}
    >
      {initializing || loggingOut ? (
        <div
          style={{
            height: '75px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '25vh',
          }}
        >
          <Loader
            style={{ width: '42px', height: '42px', borderWidth: '4px' }}
          />
        </div>
      ) : (
        children
      )}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserProvider;
