import type { TPossibleVariants } from './variants';
import type { colorStates } from './variants/_colors';
import type { LinkProps } from 'next/link';
import type { RefObject } from 'react';

import Link from 'next/link';
import { useRef } from 'react';
import { useEffect } from 'react';
import React from 'react';

import ButtonText from './ButtonText';
import IconContainer from './IconContainer';
import LoaderContainer from './LoaderContainer';
import StyledButton from './StyledButton';
import StyledLink from './StyledLink';

export interface IButtonProps {
  children?: null;
  title?: React.ReactNode | string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  rounded?: boolean;
  variant?: TPossibleVariants;
  colorStates?: colorStates;
  link?: LinkProps;
  loader?: React.ReactNode;
  isLoading?: boolean;
  linkProps?: object;
  buttonProps?: object;
  textProps?: object;
  disabled?: boolean;
  iconContainerProps?: object;
  ref?: RefObject<HTMLInputElement>;
  focus?: boolean;
}

/**
 * @example
 *   <Button
 *     title='My Awesome title!'
 *     variant='primary'
 *   />
 */

const Button = ({ title, ...props }: IButtonProps) => {
  if (props.disabled || props.isLoading) {
    // @ts-ignore TODO: i couldn't figure out how to resolve this, it's probably something wrong with styled-components. We can resolve this in the future when we have more time for refactoring
    props.buttonProps.onClick = null;
  }
  const ref = useRef();

  useEffect(() => {
    if (props.focus) {
      // Move element into view when it is focused
      ref.current.focus();
    }
  }, [props.focus]);

  const ButtonComponent = () => (
    <StyledButton
      className="button"
      {...props}
      {...props.buttonProps}
      ref={ref}
    >
      {props.loader && (
        <LoaderContainer isLoading={props.isLoading}>
          {props.loader}
        </LoaderContainer>
      )}
      {props.leftIcon && (
        <IconContainer
          isLoading={props.isLoading}
          iconAlign={'left'}
          disableMargin={!title}
          variant={props.variant}
          className="button-iconContainer left"
          {...props.iconContainerProps}
        >
          {props.leftIcon}
        </IconContainer>
      )}
      {title && (
        <ButtonText {...props} {...props.textProps}>
          {title}
        </ButtonText>
      )}
      {props.rightIcon && (
        <IconContainer
          right
          isLoading={props.isLoading}
          iconAlign={'right'}
          disableMargin={!title}
          variant={props.variant}
          className="button-iconContainer right"
          {...props.iconContainerProps}
        >
          {props.rightIcon}
        </IconContainer>
      )}
    </StyledButton>
  );

  if (props.link) {
    return (
      <Link {...props.link} passHref>
        <StyledLink {...props.linkProps}>
          <ButtonComponent />
        </StyledLink>
      </Link>
    );
  }

  return <ButtonComponent />;
};

const defaultProps: IButtonProps = {
  title: null,
  variant: 'primary',
  rounded: false,
  link: undefined,
  loader: null,
  isLoading: false,
  linkProps: {},
  buttonProps: {},
  textProps: {},
};

Button.defaultProps = defaultProps;

export default Button;
