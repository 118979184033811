import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  top: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9998;
`;
