import type { IVariantProps } from './index';

import { css } from 'styled-components';

const secondaryAlt = (props: IVariantProps) => ({
  button: css`
    position: relative;
    display: grid;
    align-content: center;
    grid-template-columns: 1fr auto;
    grid-template-areas: 'text icon';
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.grey6};
    background: transparent;
    transition: all 0.25s;
  `,
  text: css`
    grid-area: text;
    display: block;
    text-align: left;
    font-size: 24px;
    color: ${(props) => props.theme.colors.grey6};
  `,
});

export default secondaryAlt;
