export const importFromLocalStorage = (name: string, fallback: any) => {
  try {
    const parsed = JSON.parse(localStorage.getItem(name));
    return parsed === null ? fallback : parsed;
  } catch (e) {
    console.log(e);
    return fallback;
  }
};

export const exportToLocalStorage = (name: string, content: any): void => {
  try {
    localStorage.setItem(name, JSON.stringify(content));
  } catch (e) {
    console.log(e);
  }
};

export const removeFromLocalStorage = (name: string): void => {
  try {
    localStorage.removeItem(name);
  } catch (e) {
    console.log(e);
  }
};
