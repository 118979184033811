import { array, object, string } from 'yup'

import fileInputSchema from '$/yup/schemas/fileInputSchema'

export const responsiveFileInputSchema = array(
  object({
    id: string(),
    file: object(fileInputSchema).required(),
  }),
)
