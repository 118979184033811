// TODO: Add more pages to enum. They can be found in the `CurrentUserProvider`
enum DeutscheBildungPageUrl {
  HOMEPAGE = '/',
  USER_SETTINGS = '/settings',
  ADD_DOCUMENTS = '/add-documents',
  CHANGE_OFFER = '/change-offer',
  CHANGE_OFFER_DONE = '/change-offer/done',
  OFFER_PREVIEW = '/offer-overview',
  CUSTOMER_PORTAL = '/customer-portal',
  CUSTOMER_PORTAL_FAQ = '/customer-portal/faq',
  CUSTOMER_PORTAL_FAQ_V1 = '/customer-portal/faq/v1',
  CUSTOMER_PORTAL_ELEARNING = '/customer-portal/elearning',
  SEMESTER_UPDATE = '/semester-update',
  VOLUNTARY_UPDATE = '/voluntary-update',
  VOLUNTARY_UPDATE_SUCCESS = '/voluntary-update/success',
  VOLUNTARY_UPDATE_UNDERSTOOD = '/voluntary-update/understood',
  YEARLY_UPDATE = '/yearly-update',
  YEARLY_UPDATE_SUCCESS = '/yearly-update/success',
  USER_FINANCING_OVERVIEW = '/financing',
  WAITING_FOR_AN_OFFER = '/waiting',
  SIGN_IN = '/signin',
  NO_OFFER = '/no-offer',
  LOGOUT = '/logout',
}

export default DeutscheBildungPageUrl
