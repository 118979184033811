/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const _pulumi__authTestUtils = /* GraphQL */ `
  query _pulumi__authTestUtils {
    _pulumi__authTestUtils {
      ok
    }
  }
`;
export const cityAutoComplete = /* GraphQL */ `
  query CityAutoComplete($query: String!, $limit: Int) {
    cityAutoComplete(query: $query, limit: $limit) {
      id
      value
    }
  }
`;
export const highSchoolAutoComplete = /* GraphQL */ `
  query HighSchoolAutoComplete($query: String!, $limit: Int) {
    highSchoolAutoComplete(query: $query, limit: $limit) {
      id
      value
    }
  }
`;
export const specializationAutoComplete = /* GraphQL */ `
  query SpecializationAutoComplete($query: String!, $limit: Int) {
    specializationAutoComplete(query: $query, limit: $limit) {
      id
      value
      subjectId
    }
  }
`;
export const mostPopularSpecializations = /* GraphQL */ `
  query MostPopularSpecializations($ids: [Int]!) {
    mostPopularSpecializations(ids: $ids) {
      id
      value
      subjectId
    }
  }
`;
export const subjectAutoComplete = /* GraphQL */ `
  query SubjectAutoComplete($query: String!, $limit: Int) {
    subjectAutoComplete(query: $query, limit: $limit) {
      id
      value
    }
  }
`;
export const countryAutoComplete = /* GraphQL */ `
  query CountryAutoComplete($query: String!, $limit: Int) {
    countryAutoComplete(query: $query, limit: $limit) {
      id
      value
    }
  }
`;
export const foundingReasonAutoComplete = /* GraphQL */ `
  query FoundingReasonAutoComplete($query: String!, $limit: Int) {
    foundingReasonAutoComplete(query: $query, limit: $limit) {
      id
      value
    }
  }
`;
export const nationalityAutoComplete = /* GraphQL */ `
  query NationalityAutoComplete($query: String!, $limit: Int) {
    nationalityAutoComplete(query: $query, limit: $limit) {
      id
      value
      isEuropean
    }
  }
`;
export const getMaximumLoan = /* GraphQL */ `
  query GetMaximumLoan($majorId: Int!, $graduationId: Int!) {
    getMaximumLoan(majorId: $majorId, graduationId: $graduationId)
  }
`;
export const getLoanLimit = /* GraphQL */ `
  query GetLoanLimit($majorId: Int, $fieldId: Int, $graduationId: Int!) {
    getLoanLimit(
      majorId: $majorId
      fieldId: $fieldId
      graduationId: $graduationId
    ) {
      minLoan
      maxLoan
    }
  }
`;
export const automatedFeedback = /* GraphQL */ `
  query AutomatedFeedback($data: AutomatedFeedbackInput!) {
    automatedFeedback(data: $data) {
      majorName
      majorId
      subjectId
      degreeId
      degreeTypeId
      variantId
      text
      allowToProceed
    }
  }
`;
export const getCurrencies = /* GraphQL */ `
  query GetCurrencies {
    getCurrencies {
      currencies {
        id
        code
        name
        deleted
      }
    }
  }
`;
export const getIdFor = /* GraphQL */ `
  query GetIdFor($input: GetIdForInput!) {
    getIdFor(input: $input) {
      id
    }
  }
`;
export const setClientStatus = /* GraphQL */ `
  query SetClientStatus($clientId: Int!, $status: Int!) {
    setClientStatus(clientId: $clientId, status: $status) {
      ok
    }
  }
`;
export const getDegreesByIds = /* GraphQL */ `
  query GetDegreesByIds($ids: [Int]!) {
    getDegreesByIds(ids: $ids) {
      id
      value
    }
  }
`;
export const disabledServicePortal = /* GraphQL */ `
  query DisabledServicePortal($email: String!) {
    disabledServicePortal(email: $email) {
      disableServicePortal
    }
  }
`;
export const emailNotExistInCognito = /* GraphQL */ `
  query EmailNotExistInCognito($email: String!) {
    emailNotExistInCognito(email: $email) {
      exist
    }
  }
`;
export const getContractVersionForContentful = /* GraphQL */ `
  query GetContractVersionForContentful($cognitoUsername: String!) {
    getContractVersionForContentful(cognitoUsername: $cognitoUsername) {
      version
    }
  }
`;
export const _pulumi__authGema = /* GraphQL */ `
  query _pulumi__authGema {
    _pulumi__authGema {
      ok
    }
  }
`;
export const _pulumi__authCognito = /* GraphQL */ `
  query _pulumi__authCognito {
    _pulumi__authCognito {
      ok
    }
  }
`;
export const getPendingSemesterUpdates = /* GraphQL */ `
  query GetPendingSemesterUpdates($semesterUpdateId: Int) {
    getPendingSemesterUpdates(semesterUpdateId: $semesterUpdateId) {
      id
      semesterType
      status
      semesterYear
      studiesEnd
    }
  }
`;
export const getNewCookie = /* GraphQL */ `
  query GetNewCookie {
    getNewCookie {
      encodedCookiePayload
    }
  }
`;
export const getContract = /* GraphQL */ `
  query GetContract($statusId: Int!) {
    getContract(statusId: $statusId) {
      id
      filename
      dateSigned
      dateCreated
    }
  }
`;
export const validateIban = /* GraphQL */ `
  query ValidateIban($iban: String!) {
    validateIban(iban: $iban) {
      isValid
      bankName
      bic
      bankCity
    }
  }
`;
export const getContractVersion = /* GraphQL */ `
  query GetContractVersion($id: Int!) {
    getContractVersion(id: $id) {
      id
      contractType
      contractVariant
      contractVersion
      nameOfContractVersion
      validFrom
      validUntil
      comment
      ruleset
    }
  }
`;
export const checkTimeBufferEligibility = /* GraphQL */ `
  query CheckTimeBufferEligibility {
    checkTimeBufferEligibility {
      isEligible
      isBeforeRepaymentStartDate
    }
  }
`;
export const calculateCreditScore = /* GraphQL */ `
  query CalculateCreditScore($data: CalculateCreditScoreInput) {
    calculateCreditScore(data: $data) {
      errorMessage
      scoreValue
      riskRatio
      scoreRange
      scoreText
      scoreInfoText
      birthDate
      birthPlace
      currentAddress {
        street
        zipCode
        city
        country
      }
      previousAddress {
        street
        zipCode
        city
        country
      }
    }
  }
`;
export const calculatePricing = /* GraphQL */ `
  query CalculatePricing($data: CalculatePricingInput!) {
    calculatePricing(data: $data) {
      income_part
      repayment_period
      contract_length
      total_costs
      over_under_founding
      money_multiple
      capping_limit
      salary_share
      annual_interest
      estimated_income
      contract_income
      initial_income
      inflation_rate
    }
  }
`;
export const calculateExpectedSalary = /* GraphQL */ `
  query CalculateExpectedSalary($data: CalculateExpectedSalaryInput) {
    calculateExpectedSalary(data: $data) {
      salary
      initialSalary
      inflationRate
      months
    }
  }
`;
export const getUserData = /* GraphQL */ `
  query GetUserData {
    getUserData {
      id
      status
      salutationId
      firstName
      lastName
      email
      englishLevel
      birthDate
      startDate
      endDate
      contractDate
      cognitoUsername
      cognitoIdentity
      schoolNote
      variantId
      expectedDegreeId
      previousEducation {
        id
        name
      }
      highSchool {
        id
        name
        city
      }
      major {
        id
        name
        subjectId
      }
      field {
        id
        name
      }
      financingPackage {
        id
        dateFrom
        dateTo
        amountTotal
      }
      financingPackageChange {
        id
        dateFrom
        dateTo
        amountTotal
      }
      documents {
        id
        name
        key
        field
        changeDataId
      }
      consultant {
        id
        firstName
        lastName
        email
        phone
        imageUrl
      }
      contract {
        id
        filename
        dateSigned
        dateCreated
      }
      additionalDocuments {
        ID
        documentName
        description
        allowMultiple
        semesterUpdateId
        documentTypeId
        DocumentAlt
      }
      loanLimit {
        minLoan
        maxLoan
      }
      personalInfosStepRejectedStatus
      changeOfferStatus
      expectedDegree {
        id
        name
      }
      campaignToken
      customerCareStatus
      yearUpdate {
        year
        IdStatus
        wasEmployed
        wasFreelancer
        wasUnemployed
        wasStudying
        hadParentalLeave
        numberOfJobs
      }
      nationalityId
      nationality {
        id
        name
        isEuropean
      }
      birthCountryId
      birthCountry {
        id
        name
      }
      birthPlace
      childrenCount
      street
      homeNumber
      additionalAddress
      zip
      city
      countryId
      country {
        id
        name
      }
      phone
      residenceStatus
      currentSemesterNumber
      remainingSemesterNumber
      ectsPoints
      ectsPointsTotal
      averageNote
      iban
      bank
      bic
      previousStudies
      previousStudiesDegree {
        id
        name
      }
      previousStudiesGrade
      universityId
      universityAlt
      restrictedStay
    }
  }
`;
export const getPendingPackage = /* GraphQL */ `
  query GetPendingPackage {
    getPendingPackage {
      id
      dateFrom
      dateTo
      amountTotal
      items {
        id
        type
        dateFrom
        dateTo
        paymentRhythmId
        numberOfPayments
        amount
        amountTotal
        moneyUsage
        isChangeRequest
        status
      }
      changeData {
        id
        startStudies
        endStudies
      }
    }
  }
`;
export const getPendingVoluntaryUpdate = /* GraphQL */ `
  query GetPendingVoluntaryUpdate {
    getPendingVoluntaryUpdate {
      status
      currentStudiesStatusId
      currentStudiesStatusAlt
      currentStudiesOccupationId
      currentStudiesOccupationAlt
      afterStudiesStatusId
      afterStudiesStatusAlt
      afterStudiesOccupationId
      afterStudiesOccupationAlt
      afterStudiesStudyDegreeId
    }
  }
`;
export const getContractRepayments = /* GraphQL */ `
  query GetContractRepayments {
    getContractRepayments {
      paid {
        date
        amount
        description
      }
    }
  }
`;
export const getCustomerPortalMediaLibraryFavoriteArticles = /* GraphQL */ `
  query GetCustomerPortalMediaLibraryFavoriteArticles {
    getCustomerPortalMediaLibraryFavoriteArticles {
      articles {
        id
        userId
        contentfulArticleId
      }
    }
  }
`;
export const getCustomerPortalMediaLibraryWatchedArticles = /* GraphQL */ `
  query GetCustomerPortalMediaLibraryWatchedArticles {
    getCustomerPortalMediaLibraryWatchedArticles {
      articles {
        id
        userId
        contentfulArticleId
      }
    }
  }
`;
export const getCustomerPortalMediaLibraryArticlesStatusByUser = /* GraphQL */ `
  query GetCustomerPortalMediaLibraryArticlesStatusByUser {
    getCustomerPortalMediaLibraryArticlesStatusByUser {
      articles {
        userId
        contentfulArticleId
        watched
        watchTime
        favorite
      }
    }
  }
`;
export const getTravelExpensesRefundForms = /* GraphQL */ `
  query GetTravelExpensesRefundForms($showFilled: Boolean) {
    getTravelExpensesRefundForms(showFilled: $showFilled) {
      proposals {
        ID
        IdEvent
        formData
        expirationDate
      }
    }
  }
`;
export const getOfferCondition = /* GraphQL */ `
  query GetOfferCondition {
    getOfferCondition {
      repaymentDuration
      repaymentBegin
      salaryShare
      status
      signingInProgress
      minMonthlySalary
      cappingLimit
      minMoneyMultiple
    }
  }
`;
export const getContractDetails = /* GraphQL */ `
  query GetContractDetails {
    getContractDetails {
      repaymentDuration
      repaymentBegin
      dateSigned
      incomePart
      cappingLimitAmountOriginal
      minimumContractStartIncome
      originalContract {
        id
        filename
        dateSigned
        dateCreated
      }
      changedContracts {
        id
        filename
        dateSigned
        dateCreated
      }
    }
  }
`;
export const getTimeBufferType = /* GraphQL */ `
  query GetTimeBufferType($timeBufferName: String!) {
    getTimeBufferType(timeBufferName: $timeBufferName) {
      name
      maxDuration
    }
  }
`;
export const getTimeBufferRequests = /* GraphQL */ `
  query GetTimeBufferRequests($statuses: [String!]) {
    getTimeBufferRequests(statuses: $statuses) {
      id
      status
      startDate
      endDate
      positions {
        startDate
        endDate
        type
      }
    }
  }
`;
