import styled from 'styled-components';

import { changeOpacityOnLoading } from './LoaderContainer';
import { includeVariant } from './variants';

import theme from '$/assets/theme';

const IconContainer = styled('div')<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) =>
    props.disableMargin
      ? '0'
      : props.iconAlign === 'left'
      ? '0 10px 0 0'
      : '0 0 0 10px'};
  transition: ${theme.transitions.normal};

  ${(props: { variant: string; [key: string]: any }) =>
    includeVariant(props, 'iconContainer')};
  ${(props) => props.isLoading && changeOpacityOnLoading};
`;

export default IconContainer;
