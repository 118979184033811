/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserData = /* GraphQL */ `
  mutation CreateUserData($data: UserDataInput!) {
    createUserData(data: $data) {
      id
    }
  }
`;
export const updateUserData = /* GraphQL */ `
  mutation UpdateUserData($data: UpdateUserDataInput!) {
    updateUserData(data: $data) {
      ok
    }
  }
`;
export const saveAbroadData = /* GraphQL */ `
  mutation SaveAbroadData($data: UserAbroadDataInput!) {
    saveAbroadData(data: $data) {
      id
    }
  }
`;
export const saveAbroadDataChange = /* GraphQL */ `
  mutation SaveAbroadDataChange($data: UserAbroadDataInput!) {
    saveAbroadDataChange(data: $data) {
      id
    }
  }
`;
export const changeData = /* GraphQL */ `
  mutation ChangeData($data: UserChangeDataInput!) {
    changeData(data: $data) {
      id
    }
  }
`;
export const changeContactData = /* GraphQL */ `
  mutation ChangeContactData($data: UserChangeContactDataInput!) {
    changeContactData(data: $data) {
      id
    }
  }
`;
export const changeEmailConfirm = /* GraphQL */ `
  mutation ChangeEmailConfirm($data: UserChangeEmailConfirmInput!) {
    changeEmailConfirm(data: $data) {
      id
    }
  }
`;
export const saveFinancialData = /* GraphQL */ `
  mutation SaveFinancialData($data: UserFinancialDataInput!) {
    saveFinancialData(data: $data) {
      id
    }
  }
`;
export const createFinanceRequirement = /* GraphQL */ `
  mutation CreateFinanceRequirement(
    $data: FinanceRequirementPackageInput
    $items: [CreateFinanceRequirementItemInput]!
  ) {
    createFinanceRequirement(data: $data, items: $items) {
      id
    }
  }
`;
export const deleteFinanceRequirement = /* GraphQL */ `
  mutation DeleteFinanceRequirement {
    deleteFinanceRequirement {
      id
    }
  }
`;
export const updateFinanceRequirement = /* GraphQL */ `
  mutation UpdateFinanceRequirement(
    $data: FinanceRequirementPackageInput
    $items: [UpdateFinanceRequirementItemInput]!
  ) {
    updateFinanceRequirement(data: $data, items: $items) {
      id
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument($document: CreateDocumentInput) {
    createDocument(document: $document) {
      id
    }
  }
`;
export const markDocumentAsSubmitted = /* GraphQL */ `
  mutation MarkDocumentAsSubmitted($documentIds: [Int!]!) {
    markDocumentAsSubmitted(documentIds: $documentIds) {
      ok
    }
  }
`;
export const removeDocument = /* GraphQL */ `
  mutation RemoveDocument($documentId: Int) {
    removeDocument(documentId: $documentId) {
      id
    }
  }
`;
export const applicationSubmitted = /* GraphQL */ `
  mutation ApplicationSubmitted($data: ApplicationSubmittedInput!) {
    applicationSubmitted(data: $data) {
      id
    }
  }
`;
export const initVerification = /* GraphQL */ `
  mutation InitVerification($data: InitVerificationInput) {
    initVerification(data: $data) {
      id
      url
    }
  }
`;
export const rejectOffer = /* GraphQL */ `
  mutation RejectOffer($data: [FormDataContentInput!]!) {
    rejectOffer(data: $data) {
      id
    }
  }
`;
export const updateCognitoIdentity = /* GraphQL */ `
  mutation UpdateCognitoIdentity($data: CognitoIdentityDataInput) {
    updateCognitoIdentity(data: $data) {
      id
    }
  }
`;
export const saveVoluntaryUpdate = /* GraphQL */ `
  mutation SaveVoluntaryUpdate($data: VoluntaryUpdateInput) {
    saveVoluntaryUpdate(data: $data) {
      id
    }
  }
`;
export const changeVoluntaryUpdateStatus = /* GraphQL */ `
  mutation ChangeVoluntaryUpdateStatus($data: VoluntaryUpdateStatusInput) {
    changeVoluntaryUpdateStatus(data: $data) {
      id
    }
  }
`;
export const userChangeContract = /* GraphQL */ `
  mutation UserChangeContract($data: UserChangeContractInput) {
    userChangeContract(data: $data) {
      id
    }
  }
`;
export const initChangeContract = /* GraphQL */ `
  mutation InitChangeContract {
    initChangeContract {
      id
    }
  }
`;
export const userCancelChangeContract = /* GraphQL */ `
  mutation UserCancelChangeContract {
    userCancelChangeContract {
      id
    }
  }
`;
export const userSetChangeContractStatus = /* GraphQL */ `
  mutation UserSetChangeContractStatus($newStatus: Int!) {
    userSetChangeContractStatus(newStatus: $newStatus) {
      id
    }
  }
`;
export const saveSemesterUpdate = /* GraphQL */ `
  mutation SaveSemesterUpdate($data: SemesterUpdateInput!) {
    saveSemesterUpdate(data: $data) {
      id
    }
  }
`;
export const updateClientEmail = /* GraphQL */ `
  mutation UpdateClientEmail($data: UpdateClientEmailInput) {
    updateClientEmail(data: $data) {
      id
    }
  }
`;
export const updateOfferStatus = /* GraphQL */ `
  mutation UpdateOfferStatus($data: UpdateOfferStatusInput) {
    updateOfferStatus(data: $data) {
      id
    }
  }
`;
export const updateStatus = /* GraphQL */ `
  mutation UpdateStatus($data: UpdateStatusInput) {
    updateStatus(data: $data) {
      ok
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($data: DeleteUserInput) {
    deleteUser(data: $data) {
      id
    }
  }
`;
export const rejectChangeOffer = /* GraphQL */ `
  mutation RejectChangeOffer {
    rejectChangeOffer {
      id
    }
  }
`;
export const updateChangeOfferStatus = /* GraphQL */ `
  mutation UpdateChangeOfferStatus($data: UpdateChangeOfferStatusInput) {
    updateChangeOfferStatus(data: $data) {
      id
    }
  }
`;
export const notifyContactChanged = /* GraphQL */ `
  mutation NotifyContactChanged($clientId: Int!) {
    notifyContactChanged(clientId: $clientId) {
      id
    }
  }
`;
export const createOffer = /* GraphQL */ `
  mutation CreateOffer($data: CreateOfferInput) {
    createOffer(data: $data) {
      id
    }
  }
`;
export const syncSemesterUpdate = /* GraphQL */ `
  mutation SyncSemesterUpdate {
    syncSemesterUpdate {
      count
    }
  }
`;
export const testOfferSigned = /* GraphQL */ `
  mutation TestOfferSigned($data: TestOfferSignedInput) {
    testOfferSigned(data: $data) {
      id
    }
  }
`;
export const mockOfferSigned = /* GraphQL */ `
  mutation MockOfferSigned($data: MockOfferSignedInput) {
    mockOfferSigned(data: $data) {
      id
    }
  }
`;
export const additionalDocuments = /* GraphQL */ `
  mutation AdditionalDocuments($documents: [AdditionalDocumentInput]!) {
    additionalDocuments(documents: $documents) {
      ids
    }
  }
`;
export const semesterUpdateDocuments = /* GraphQL */ `
  mutation SemesterUpdateDocuments(
    $documents: [AdditionalDocumentInput]!
    $semesterUpdateId: Int!
  ) {
    semesterUpdateDocuments(
      documents: $documents
      semesterUpdateId: $semesterUpdateId
    ) {
      ids
    }
  }
`;
export const mockRequireSemesterUpdate = /* GraphQL */ `
  mutation MockRequireSemesterUpdate($data: MockRequireSemesterUpdateInput!) {
    mockRequireSemesterUpdate(data: $data) {
      id
    }
  }
`;
export const customerIoUnsubscribe = /* GraphQL */ `
  mutation CustomerIoUnsubscribe($data: CustomerIoUnsubscribeInput!) {
    customerIoUnsubscribe(data: $data) {
      id
    }
  }
`;
export const mockRequireYearUpdate = /* GraphQL */ `
  mutation MockRequireYearUpdate($data: MockRequireYearUpdateInput!) {
    mockRequireYearUpdate(data: $data) {
      id
    }
  }
`;
export const saveYearUpdate = /* GraphQL */ `
  mutation SaveYearUpdate($data: YearUpdateInput!) {
    saveYearUpdate(data: $data) {
      id
    }
  }
`;
export const initContactDataChange = /* GraphQL */ `
  mutation InitContactDataChange($data: InitContactDataChangeInput!) {
    initContactDataChange(data: $data) {
      id
    }
  }
`;
export const sendCustomForm = /* GraphQL */ `
  mutation SendCustomForm($data: FormDataInput) {
    sendCustomForm(data: $data) {
      success
      message
    }
  }
`;
export const customerPortalMediaLibraryAddFavoriteArticle = /* GraphQL */ `
  mutation CustomerPortalMediaLibraryAddFavoriteArticle(
    $data: customerPortalMediaLibraryAddFavoriteArticleInput!
  ) {
    customerPortalMediaLibraryAddFavoriteArticle(data: $data) {
      id
    }
  }
`;
export const customerPortalMediaLibraryAddWatchedArticle = /* GraphQL */ `
  mutation CustomerPortalMediaLibraryAddWatchedArticle(
    $data: customerPortalMediaLibraryAddWatchedArticleInput!
  ) {
    customerPortalMediaLibraryAddWatchedArticle(data: $data) {
      id
    }
  }
`;
export const customerPortalMediaLibraryDeleteFavoriteArticle = /* GraphQL */ `
  mutation CustomerPortalMediaLibraryDeleteFavoriteArticle(
    $data: customerPortalMediaLibraryDeleteFavoriteArticleInput!
  ) {
    customerPortalMediaLibraryDeleteFavoriteArticle(data: $data) {
      success
      message
    }
  }
`;
export const customerPortalMediaLibraryDeleteWatchedArticle = /* GraphQL */ `
  mutation CustomerPortalMediaLibraryDeleteWatchedArticle(
    $data: customerPortalMediaLibraryDeleteWatchedArticleInput!
  ) {
    customerPortalMediaLibraryDeleteWatchedArticle(data: $data) {
      success
      message
    }
  }
`;
export const customerPortalMediaLibraryUpdateWatchedArticle = /* GraphQL */ `
  mutation CustomerPortalMediaLibraryUpdateWatchedArticle(
    $data: customerPortalMediaLibraryUpdateWatchedArticleInput!
  ) {
    customerPortalMediaLibraryUpdateWatchedArticle(data: $data) {
      success
      message
    }
  }
`;
export const createTravelExpensesRefundProposal = /* GraphQL */ `
  mutation CreateTravelExpensesRefundProposal(
    $data: createTravelExpensesRefundProposalInput!
  ) {
    createTravelExpensesRefundProposal(data: $data) {
      id
      clientId
      eventId
      expirationDate
    }
  }
`;
export const updateTravelExpensesRefund = /* GraphQL */ `
  mutation UpdateTravelExpensesRefund($data: updateTravelExpensesRefundInput!) {
    updateTravelExpensesRefund(data: $data) {
      success
      message
    }
  }
`;
export const deleteTravelExpensesRefund = /* GraphQL */ `
  mutation DeleteTravelExpensesRefund($travelExpensesRefundId: Int!) {
    deleteTravelExpensesRefund(
      travelExpensesRefundId: $travelExpensesRefundId
    ) {
      success
      message
    }
  }
`;
export const askForAdditionalDocuments = /* GraphQL */ `
  mutation AskForAdditionalDocuments($data: askForAdditionalDocumentsInput!) {
    askForAdditionalDocuments(data: $data) {
      success
      message
    }
  }
`;
export const saveAdBlockDetection = /* GraphQL */ `
  mutation SaveAdBlockDetection($data: saveAdblockDetectionInput!) {
    saveAdBlockDetection(data: $data) {
      success
      message
    }
  }
`;
export const changeOfferRequestContact = /* GraphQL */ `
  mutation ChangeOfferRequestContact {
    changeOfferRequestContact {
      success
      message
    }
  }
`;
export const addContractVersion = /* GraphQL */ `
  mutation AddContractVersion($data: addContractVersionInput!) {
    addContractVersion(data: $data) {
      id
    }
  }
`;
export const updateContractVersion = /* GraphQL */ `
  mutation UpdateContractVersion($data: updateContractVersionInput!) {
    updateContractVersion(data: $data) {
      success
    }
  }
`;
export const deleteContractVersion = /* GraphQL */ `
  mutation DeleteContractVersion($data: deleteContractVersionInput!) {
    deleteContractVersion(data: $data) {
      success
    }
  }
`;
export const calculateTimeBufferCost = /* GraphQL */ `
  mutation CalculateTimeBufferCost($data: calculateTimeBufferCostInput!) {
    calculateTimeBufferCost(data: $data) {
      totalRepaymentPeriodExtension
    }
  }
`;
