export const getPendingPackageNoItems = /* GraphQL */ `
  query GetPendingPackageNoItems {
    getPendingPackage {
      id
      dateFrom
      dateTo
      amountTotal
    }
  }
`;

export const getUserData = /* GraphQL */ `
  query GetUserDataCustom {
    getUserData {
      id
      status
      salutationId
      firstName
      lastName
      email
      englishLevel
      birthDate
      startDate
      endDate
      contractDate
      cognitoUsername
      cognitoIdentity
      schoolNote
      variantId
      expectedDegreeId
      previousEducation {
        id
        name
      }
      highSchool {
        id
        name
        city
      }
      major {
        id
        name
        subjectId
      }
      field {
        id
        name
      }
      financingPackage {
        id
        dateFrom
        dateTo
        amountTotal
        items {
          id
          type
          dateFrom
          dateTo
          paymentRhythmId
          numberOfPayments
          amount
          amountTotal
          moneyUsage
          isChangeRequest
          status
        }
        changeData {
          id
          startStudies
          endStudies
        }
      }
      financingPackageChange {
        id
        dateFrom
        dateTo
        amountTotal
        items {
          id
          type
          dateFrom
          dateTo
          paymentRhythmId
          numberOfPayments
          amount
          amountTotal
          moneyUsage
          isChangeRequest
          status
        }
        changeData {
          id
          startStudies
          endStudies
        }
      }
      documents {
        id
        name
        key
        field
        changeDataId
      }
      consultant {
        id
        firstName
        lastName
        email
        phone
        imageUrl
      }
      contract {
        id
        filename
        dateSigned
        dateCreated
      }
      additionalDocuments {
        ID
        documentName
        description
        allowMultiple
        semesterUpdateId
        DocumentAlt
        documentTypeId
      }
      loanLimit {
        minLoan
        maxLoan
      }

      personalInfosStepRejectedStatus
      changeOfferStatus
      expectedDegree {
        id
        name
      }
      campaignToken
      customerCareStatus
      yearUpdate {
        year
        IdStatus
        wasEmployed
        wasFreelancer
        wasUnemployed
        wasStudying
        hadParentalLeave
        numberOfJobs
        jobs {
          id
          type
          dateStart
          dateEnd
          currencyCode
          totalSalary
          bonusSalary
          employerName
          jobDescription
          constantIncome
          months {
            id
            month
            salaryAmount
            workingHours
            year
          }
        }
        unemployed {
          officiallyUnemployed
          dateFrom
          dateTo
        }
        studied {
          dateFrom
          dateTo
        }
        parentalLeave {
          dateFrom
          dateTo
        }
      }
      nationalityId
      nationality {
        id
        name
        isEuropean
      }
      birthCountryId
      birthCountry {
        id
        name
      }
      birthPlace
      childrenCount
      street
      homeNumber
      additionalAddress
      zip
      city
      countryId
      country {
        id
        name
      }
      phone
      residenceStatus
      currentSemesterNumber
      remainingSemesterNumber
      ectsPoints
      ectsPointsTotal
      averageNote
      iban
      bank
      bic
      previousStudies
      previousStudiesDegree {
        id
        name
      }
      previousStudiesGrade
    }
  }
`;
