import styled from 'styled-components';

const Icon = styled('div')<{ size?: string }>`
  grid-area: icon;
  display: flex;
  align-items: center;
  justify-content: center;
`;

Icon.defaultProps = {
  className: 'toast-icon',
};

export default Icon;
