import colors from './colors';

export type TPalletColor = 'primary' | 'secondary';

const palette: {
  [index in TPalletColor]: string;
} = {
  primary: colors.brandGreen,
  secondary: colors.grey6,
};

export default palette;
