import type { IVariantProps } from './index';

import { css } from 'styled-components';

import colors from './_colors';

const transparent = (props: IVariantProps) => ({
  button: css`
    position: relative;
    background: transparent;
    padding: 16px 0;
    margin: 0 30px;

    &::before {
      content: '';
      position: absolute;
      bottom: 16px;
      height: 2px;
      width: 100%;
      background: ${(props) => props.theme.colors.transparent};
      transition: all ${(props) => props.theme.transitions.normal};
    }

    &.selected,
    &:hover {
      &::before {
        background: ${colors[props.color.hover]};
      }
    }
  `,
  text: css`
    font-weight: 400;
    color: ${colors[props.color.unset]};
  `,
});

export default transparent;
