import type { colorStates } from './_colors';

interface ColorVariant {
  [key: string]: colorStates;
}

const colorVariants: ColorVariant = {
  primary: {
    unset: 'primary',
    hover: 'primary',
    active: 'primary',
    focus: 'primary',
    disabled: 'brandGreen25',
  },
  secondary: {
    unset: 'secondary',
    hover: 'grey7',
    active: 'secondary',
    focus: 'secondary',
    disabled: 'grey2',
  },
  secondaryAlt: {
    unset: 'white',
    hover: 'grey3',
    active: 'grey2',
    focus: 'secondary',
    disabled: 'white',
  },
  text: {
    unset: 'grey7',
    hover: 'secondary',
    active: 'secondary',
    focus: 'secondary',
    disabled: 'white',
  },
  answer: {
    unset: 'grey7',
    hover: 'secondary',
    active: 'secondary',
    focus: 'secondary',
    disabled: 'white',
  },
  transparent: {
    unset: 'black',
    hover: 'black',
    active: 'black',
    focus: 'black',
    disabled: 'black',
  },
  menu: {
    unset: 'black',
    hover: 'brandBlue10',
    active: 'secondary',
    focus: 'secondary',
    disabled: 'white',
  },
};

export default colorVariants;
