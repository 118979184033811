import { css } from 'styled-components';

const trueTransparent = () => ({
  button: css`
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    background: transparent;
  `,
  text: css`
    font-weight: 400;
    font-size: 18px;
  `,
});

export default trueTransparent;
