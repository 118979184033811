import * as Bowser from 'bowser';
import isBot from 'isbot';
import { useState } from 'react';

import { Overlay } from './Overlay';
import { TextBox } from './TextBox';

import Button from '$/components/elements/Button';
import {
  exportToLocalStorage,
  importFromLocalStorage,
} from '$/utils/localstorage';

const LOCAL_STORAGE_KEY = 'NotSupportedBrowserNotification';
const BROWSER_SATISFACTION = {
  chrome: '>=64',
  firefox: '>=67',
  'internet explorer': '>=11',
  edge: '>=79',
  safari: '>=12',
  opera: '>=51',
};

const NotSupportedBrowserNotification = () => {
  let isBrowserSupported = true;
  const useragent = navigator.userAgent;

  if (useragent) {
    const browser = Bowser.getParser(useragent ?? '');
    const isSupported = browser.satisfies(BROWSER_SATISFACTION);
    isBrowserSupported =
      typeof isSupported === 'undefined' ||
      isSupported ||
      isBot(useragent ?? '');
  }

  const [hideNotification, setHideNotification] = useState(
    importFromLocalStorage(LOCAL_STORAGE_KEY, false)
  );

  const closeNotification = () => {
    exportToLocalStorage(LOCAL_STORAGE_KEY, true);
    setHideNotification(true);
  };

  return (
    <>
      {!isBrowserSupported && !hideNotification && (
        <Overlay>
          <TextBox>
            <div className="content">
              <p>
                Es scheint, als würdest du eine ältere Browserversion nutzen.
                Dies führt möglicherweise zu Problemen in der Nutzung unseres
                Kundenportals. Wir empfehlen dir, einen anderen Browser oder
                eine aktuelle Version deines Browsers zu verwenden, um eine
                fehlerfreie Nutzung sicherzustellen.
              </p>
              <div className="button-container">
                <Button
                  title="OK"
                  variant="secondary"
                  buttonProps={{ onClick: closeNotification }}
                ></Button>
              </div>
            </div>
          </TextBox>
        </Overlay>
      )}
    </>
  );
};

export default NotSupportedBrowserNotification;
