import type {
  TEventsGetEventsRequest,
  TEventsGetEventsResponse,
} from '$/types/api/events';
import type {
  TMediaLibraryArticlesRequest,
  TMediaLibraryArticlesResponse,
} from '$/types/api/media-library';
import type { IGetBankNameFromIbanRequest } from '$/types/api-client/getBankNameFromIban';
import type { TQuestionAnswer } from '$/types/components/questions';
import type VoluntaryUpdateStatus from '$/types/enums/VoluntaryUpdateStatus';
import type {
  AdditionalDocumentsMutation,
  AdditionalDocumentsMutationVariables,
  AutomatedFeedbackQueryVariables,
  AutomatedFeedbackResponse,
  ChangeContactDataMutationVariables,
  ChangeEmailConfirmMutationVariables,
  ChangeVoluntaryUpdateStatusMutationVariables,
  CognitoIdentityDataInput,
  CreateDocumentMutation,
  CreateDocumentMutationVariables,
  CreateFinanceRequirementMutation,
  CreateFinanceRequirementMutationVariables,
  CreateTravelExpensesRefundProposalMutationVariables,
  CreateTravelExpensesRefundProposalResponse,
  CreateUserDataMutation,
  CustomerPortalMediaLibraryAddFavoriteArticleMutationVariables,
  CustomerPortalMediaLibraryAddWatchedArticleMutationVariables,
  CustomerPortalMediaLibraryDeleteFavoriteArticleMutationVariables,
  CustomerPortalMediaLibraryDeleteWatchedArticleMutationVariables,
  CustomerPortalMediaLibraryUpdateWatchedArticleMutationVariables,
  DeleteTravelExpensesRefundMutationVariables,
  DisabledServicePortalQuery,
  DisabledServicePortalQueryVariables,
  FormDataInput,
  GenericAnonymousSuccessResponse,
  GenericResultResponse,
  GenericSuccessResponse,
  GetCurrenciesResponse,
  GetCustomerPortalMediaLibraryArticlesStatusByUserResponse,
  GetCustomerPortalMediaLibraryFavoriteArticlesResponse,
  GetCustomerPortalMediaLibraryWatchedArticlesResponse,
  GetDegreesByIdsQueryVariables,
  GetNewCookieQuery,
  GetPendingPackageQuery,
  GetPendingSemesterUpdatesQuery,
  GetPendingSemesterUpdatesQueryVariables,
  GetTimeBufferTypeQuery,
  GetTimeBufferTypeQueryVariables,
  GetTravelExpensesRefundFormsQuery,
  GetTravelExpensesRefundFormsQueryVariables,
  GetUserDataQuery,
  InitContactDataChangeInput,
  InitVerificationMutation,
  MostPopularSpecializationsQueryVariables,
  RejectOfferMutationVariables,
  RejectOfferResponse,
  RemoveDocumentMutation,
  RemoveDocumentMutationVariables,
  RepaymentsResponse,
  SaveAdBlockDetectionMutationVariables,
  SaveFinancialDataMutation,
  SaveFinancialDataMutationVariables,
  SaveSemesterUpdateMutationVariables,
  SaveVoluntaryUpdateMutationVariables,
  SaveYearUpdateMutation,
  SaveYearUpdateMutationVariables,
  SemesterUpdateDocumentsMutationVariables,
  UpdateCognitoIdentityMutation,
  UpdateFinanceRequirementMutation,
  UpdateFinanceRequirementMutationVariables,
  UpdateTravelExpensesRefundMutationVariables,
  UpdateUserDataMutationVariables,
  UserChangeContractMutationVariables,
  UserChangeContractResponse,
  UserChangeDataInput,
  UserChangeInputResponse,
  UserDataInput,
  ValidateIbanQuery,
} from './API';
import type { GraphQLResult } from '@aws-amplify/api-graphql';
import type { AxiosResponse } from 'axios';

import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';
import axios from 'axios';

import * as customQueries from './graphql/customQueries';
import { getUserData } from './graphql/customQueries';
import * as mutations from './graphql/mutations';
import * as queries from './graphql/queries';
class ApiClient {
  // TODO: Fix return type
  static async getUserData(): Promise<GetUserDataQuery> {
    const result = (await API.graphql({
      query: getUserData,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GetUserDataQuery>;

    return result.data;
  }

  static async updateUserData(
    variables: UpdateUserDataMutationVariables
  ): Promise<GenericResultResponse> {
    const result = (await API.graphql({
      query: mutations.updateUserData,
      variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericResultResponse>;

    return result.data;
  }

  static async updateCognitoIdentity(
    data: CognitoIdentityDataInput
  ): Promise<UpdateCognitoIdentityMutation> {
    const result = (await API.graphql({
      query: mutations.updateCognitoIdentity,
      variables: { data },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<UpdateCognitoIdentityMutation>;

    return result.data;
  }

  static async createUserData(
    data: UserDataInput
  ): Promise<CreateUserDataMutation> {
    const result = (await API.graphql({
      query: mutations.createUserData,
      variables: { data },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })) as GraphQLResult<CreateUserDataMutation>;

    return result.data;
  }

  static async createFinanceRequirement(
    variables: CreateFinanceRequirementMutationVariables
  ): Promise<CreateFinanceRequirementMutation> {
    const result = (await API.graphql({
      query: mutations.createFinanceRequirement,
      variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<CreateFinanceRequirementMutation>;

    return result.data;
  }

  static async updateFinanceRequirement(
    variables: UpdateFinanceRequirementMutationVariables
  ): Promise<UpdateFinanceRequirementMutation> {
    const result = (await API.graphql({
      query: mutations.updateFinanceRequirement,
      variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<UpdateFinanceRequirementMutation>;

    return result.data;
  }

  static async deleteFinanceRequirement(): Promise<GenericResultResponse> {
    const result = (await API.graphql({
      query: mutations.deleteFinanceRequirement,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericResultResponse>;

    return result.data;
  }

  static async initIdNow(): Promise<InitVerificationMutation> {
    const result = (await API.graphql({
      query: mutations.initVerification,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<InitVerificationMutation>;

    return result.data;
  }

  static async generateNewCookiePayload(): Promise<GetNewCookieQuery> {
    const result = (await API.graphql({
      query: queries.getNewCookie,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GetNewCookieQuery>;

    return result.data;
  }

  static async emailNotExistInCognito(
    variables: EmailNotExistInCognitoQueryVariables
  ) {
    const result = (await API.graphql({
      query: queries.emailNotExistInCognito,
      variables,
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })) as GraphQLResult<EmailNotExistInCognitoQuery>;

    return result.data?.emailNotExistInCognito;
  }

  static async createDocument(
    variables: CreateDocumentMutationVariables
  ): Promise<CreateDocumentMutation> {
    const result = (await API.graphql({
      query: mutations.createDocument,
      variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<CreateDocumentMutation>;

    return result.data;
  }

  static async removeDocument(
    variables: RemoveDocumentMutationVariables
  ): Promise<RemoveDocumentMutation> {
    const result = (await API.graphql({
      query: mutations.removeDocument,
      variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<RemoveDocumentMutation>;

    return result.data;
  }

  static async additionalDocuments(
    variables: AdditionalDocumentsMutationVariables
  ): Promise<AdditionalDocumentsMutation> {
    const result = (await API.graphql({
      query: mutations.additionalDocuments,
      variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<AdditionalDocumentsMutation>;

    return result.data;
  }

  static async changeContractData(
    variables: UserChangeContractMutationVariables
  ): Promise<UserChangeContractResponse> {
    const result = (await API.graphql({
      query: mutations.userChangeContract,
      variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<UserChangeContractResponse>;

    return result.data;
  }

  static async getPendingPackage(
    includeItems = false
  ): Promise<GetPendingPackageQuery> {
    const result = (await API.graphql({
      query: includeItems
        ? queries.getPendingPackage
        : customQueries.getPendingPackageNoItems,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GetPendingPackageQuery>;

    return result.data;
  }

  static async initChangeContract(): Promise<GenericResultResponse> {
    const result = (await API.graphql({
      query: mutations.initChangeContract,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericResultResponse>;

    return result.data;
  }

  static async setChangeContractStatus(
    newStatus: number
  ): Promise<GenericResultResponse> {
    const result = (await API.graphql({
      query: mutations.userSetChangeContractStatus,
      variables: { newStatus },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericResultResponse>;

    return result.data;
  }

  static async saveVoluntaryUpdate(data: SaveVoluntaryUpdateMutationVariables) {
    const result = (await API.graphql({
      query: mutations.saveVoluntaryUpdate,
      variables: data,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericResultResponse>;

    // TODO: fix types
    //@ts-ignore
    return result.data.saveVoluntaryUpdate;
  }

  static async changeVoluntaryUpdateStatus(data: {
    data: {
      status: VoluntaryUpdateStatus;
    } & ChangeVoluntaryUpdateStatusMutationVariables;
  }) {
    const result = (await API.graphql({
      query: mutations.changeVoluntaryUpdateStatus,
      variables: data,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericResultResponse>;

    return result.data;
  }

  static async saveSemesterUpdate(
    data: SaveSemesterUpdateMutationVariables
  ): Promise<GenericResultResponse> {
    const result = (await API.graphql({
      query: mutations.saveSemesterUpdate,
      variables: data,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericResultResponse>;

    return result.data;
  }

  static async semesterUpdateDocuments(
    variables: SemesterUpdateDocumentsMutationVariables
  ): Promise<AdditionalDocumentsMutation> {
    const result = (await API.graphql({
      query: mutations.semesterUpdateDocuments,
      variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<AdditionalDocumentsMutation>;

    return result.data;
  }

  static async changeData(
    data: UserChangeDataInput
  ): Promise<UserChangeInputResponse> {
    const result = (await API.graphql({
      query: mutations.changeData,
      variables: { data },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<UserChangeInputResponse>;

    return result.data!;
  }

  static async getCurrencies(): Promise<GetCurrenciesResponse> {
    const result = (await API.graphql({
      query: queries.getCurrencies,
    })) as GraphQLResult<{ getCurrencies: GetCurrenciesResponse }>;

    // TODO: Add german currency names. Remove filter and unnecessary object afterwards
    const getCurrencies = {
      __typename: 'GetCurrenciesResponse',
      currencies: result.data?.getCurrencies.currencies.filter(
        (item) => !!item.name
      ),
    } as GetCurrenciesResponse;

    return getCurrencies!;
  }

  static async initContactDataChange(
    data: InitContactDataChangeInput
  ): Promise<GenericResultResponse> {
    const result = (await API.graphql({
      query: mutations.initContactDataChange,
      variables: { data: data },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericResultResponse>;

    return result.data as GenericResultResponse;
  }

  static async changeContactData(
    data: ChangeContactDataMutationVariables
  ): Promise<GenericResultResponse> {
    const result = (await API.graphql({
      variables: data,
      query: mutations.changeContactData,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericResultResponse>;

    return result.data as GenericResultResponse;
  }

  static async changeEmailConfirm(
    data: ChangeEmailConfirmMutationVariables
  ): Promise<GenericResultResponse> {
    const result = (await API.graphql({
      query: mutations.changeEmailConfirm,
      variables: data,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericResultResponse>;

    return result.data as GenericResultResponse;
  }

  static async saveYearUpdate(variables: SaveYearUpdateMutationVariables) {
    const result = (await API.graphql({
      query: mutations.saveYearUpdate,
      variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<SaveYearUpdateMutation>;

    return result!;
  }

  static async saveFinancialData(
    variables: SaveFinancialDataMutationVariables
  ): Promise<GraphQLResult<SaveFinancialDataMutation>> {
    return (await API.graphql({
      query: mutations.saveFinancialData,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<SaveFinancialDataMutation>;
  }

  static async getBankNameFromIban(
    variables: IGetBankNameFromIbanRequest
  ): Promise<ValidateIbanQuery['validateIban'] | undefined> {
    const result = (await API.graphql({
      query: queries.validateIban,
      variables: { iban: variables.iban },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<ValidateIbanQuery>;

    return result.data?.validateIban;
  }

  static async sendCustomForm(
    data: FormDataInput
  ): Promise<GenericSuccessResponse | undefined> {
    const result = (await API.graphql({
      query: mutations.sendCustomForm,
      variables: { data },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericSuccessResponse>;

    return result.data;
  }

  static async getMostPopularSpecializations(
    variables: MostPopularSpecializationsQueryVariables
  ): Promise<TQuestionAnswer[]> {
    const result = (await API.graphql({
      query: queries.mostPopularSpecializations,
      variables: { ids: variables.ids },
    })) as GraphQLResult<{ mostPopularSpecializations: TQuestionAnswer[] }>;

    return result.data?.mostPopularSpecializations ?? [];
  }

  static async getContractRepayments(): Promise<
    RepaymentsResponse | undefined
  > {
    const results = (await API.graphql({
      query: queries.getContractRepayments,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<{ getContractRepayments: RepaymentsResponse }>;

    return results.data?.getContractRepayments;
  }

  static getCustomerPortalMediaLibraryArticles(
    params: TMediaLibraryArticlesRequest
  ): Promise<AxiosResponse<TMediaLibraryArticlesResponse>> {
    if (params.articleIds?.length === 0) {
      params.articleIds = [''];
    }
    return axios.get<TMediaLibraryArticlesResponse>(
      '/api/media-library/articles',
      { params }
    );
  }

  static getEvents(
    params: TEventsGetEventsRequest
  ): Promise<AxiosResponse<TEventsGetEventsResponse>> {
    return axios.get<TEventsGetEventsResponse>('/api/events', { params });
  }

  static async customerPortalMediaLibraryAddFavoriteArticle(
    variables: CustomerPortalMediaLibraryAddFavoriteArticleMutationVariables
  ): Promise<GenericResultResponse | undefined> {
    const result = (await API.graphql({
      query: mutations.customerPortalMediaLibraryAddFavoriteArticle,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericResultResponse>;

    return result.data;
  }

  static async customerPortalMediaLibraryGetFavoriteArticles(): Promise<
    GetCustomerPortalMediaLibraryFavoriteArticlesResponse | undefined
  > {
    const result = (await API.graphql({
      query: queries.getCustomerPortalMediaLibraryFavoriteArticles,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GetCustomerPortalMediaLibraryFavoriteArticlesResponse>;

    return result.data;
  }

  static async customerPortalMediaLibraryDeleteFavoriteArticle(
    variables: CustomerPortalMediaLibraryDeleteFavoriteArticleMutationVariables
  ): Promise<GenericResultResponse | undefined> {
    const result = (await API.graphql({
      query: mutations.customerPortalMediaLibraryDeleteFavoriteArticle,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericResultResponse>;

    return result.data;
  }

  static async customerPortalMediaLibraryAddWatchedArticle(
    variables: CustomerPortalMediaLibraryAddWatchedArticleMutationVariables
  ): Promise<GenericResultResponse | undefined> {
    const result = (await API.graphql({
      query: mutations.customerPortalMediaLibraryAddWatchedArticle,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericResultResponse>;

    return result.data;
  }

  static async customerPortalMediaLibraryGetWatchedArticles(): Promise<
    GetCustomerPortalMediaLibraryWatchedArticlesResponse | undefined
  > {
    const result = (await API.graphql({
      query: queries.getCustomerPortalMediaLibraryWatchedArticles,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GetCustomerPortalMediaLibraryWatchedArticlesResponse>;

    return result.data;
  }

  static async customerPortalMediaLibraryDeleteWatchedArticle(
    variables: CustomerPortalMediaLibraryDeleteWatchedArticleMutationVariables
  ): Promise<GenericResultResponse | undefined> {
    const result = (await API.graphql({
      query: mutations.customerPortalMediaLibraryDeleteWatchedArticle,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericResultResponse>;

    return result.data;
  }

  static async customerPortalMediaLibraryUpdateWatchedArticle(
    variables: CustomerPortalMediaLibraryUpdateWatchedArticleMutationVariables
  ): Promise<GenericResultResponse | undefined> {
    const result = (await API.graphql({
      query: mutations.customerPortalMediaLibraryUpdateWatchedArticle,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericResultResponse>;

    return result.data;
  }

  static async getCustomerPortalMediaLibraryGetArticlesStatus(): Promise<
    GetCustomerPortalMediaLibraryArticlesStatusByUserResponse | undefined
  > {
    const result = (await API.graphql({
      query: queries.getCustomerPortalMediaLibraryArticlesStatusByUser,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<{
      getCustomerPortalMediaLibraryArticlesStatusByUser: GetCustomerPortalMediaLibraryArticlesStatusByUserResponse;
    }>;

    return result.data?.getCustomerPortalMediaLibraryArticlesStatusByUser;
  }

  static async sendRejectOfferReason(
    variables: RejectOfferMutationVariables
  ): Promise<RejectOfferResponse | undefined> {
    const result = (await API.graphql({
      query: mutations.rejectOffer,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<RejectOfferResponse>;

    return result.data;
  }

  static async createTravelExpensesRefundProposal(
    variables: CreateTravelExpensesRefundProposalMutationVariables
  ): Promise<CreateTravelExpensesRefundProposalResponse | undefined> {
    throw new Error('Not implemented auth method');
    // const result = (await API.graphql({
    //   query: mutations.createTravelExpensesRefundProposal,
    //   variables: variables,
    //   authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    // })) as GraphQLResult<CreateTravelExpensesRefundProposalResponse>
    //
    // return result.data
  }

  static async updateTravelExpensesRefund(
    variables: UpdateTravelExpensesRefundMutationVariables
  ): Promise<GenericAnonymousSuccessResponse | undefined> {
    const result = (await API.graphql({
      query: mutations.updateTravelExpensesRefund,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericAnonymousSuccessResponse>;

    return result.data;
  }

  static async deleteTravelExpensesRefund(
    variables: DeleteTravelExpensesRefundMutationVariables
  ): Promise<GenericAnonymousSuccessResponse | undefined> {
    throw new Error('Not implemented auth method');
    // const result = (await API.graphql({
    //   query: mutations.deleteTravelExpensesRefund,
    //   variables: variables,
    //   authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    // })) as GraphQLResult<GenericAnonymousSuccessResponse>
    //
    // return result.data
  }

  static async getPendingSemesterUpdates(
    variables?: GetPendingSemesterUpdatesQueryVariables
  ): Promise<GetPendingSemesterUpdatesQuery['getPendingSemesterUpdates']> {
    const result = (await API.graphql({
      query: queries.getPendingSemesterUpdates,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GetPendingSemesterUpdatesQuery>;

    if (result.data) {
      return result.data.getPendingSemesterUpdates;
    }

    console.error('Cannot get pending semester updates', result);
    throw new Error(
      result.errors?.[0]?.message ?? 'Cannot get pending semester updates'
    );
  }

  static async getTravelExpensesRefundForms(
    variables: GetTravelExpensesRefundFormsQueryVariables
  ): Promise<GetTravelExpensesRefundFormsQuery | undefined> {
    const result = (await API.graphql({
      query: queries.getTravelExpensesRefundForms,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GetTravelExpensesRefundFormsQuery>;

    return result.data;
  }

  static async getEventsById(
    params: TEventsGetEventsRequest
  ): Promise<AxiosResponse<TEventsGetEventsResponse>> {
    if (params.eventsId?.length === 0) params.eventsId = [''];
    return axios.get<TEventsGetEventsResponse>(`/api/events/getEvents`, {
      params,
    });
  }

  static async setAdblockDetection(
    variables: SaveAdBlockDetectionMutationVariables
  ): Promise<GenericResultResponse | undefined> {
    const result = (await API.graphql({
      query: mutations.saveAdBlockDetection,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericResultResponse>;

    return result.data;
  }

  static async changeOfferRequestContact(): Promise<
    GenericSuccessResponse | undefined
  > {
    const result = (await API.graphql({
      query: mutations.changeOfferRequestContact,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GenericSuccessResponse>;

    return result.data;
  }

  static async getDegreesByIds(
    variables: GetDegreesByIdsQueryVariables
  ): Promise<TQuestionAnswer[]> {
    const result = (await API.graphql({
      query: queries.getDegreesByIds,
      variables: { ids: variables.ids },
    })) as GraphQLResult<{ getDegreesByIds: TQuestionAnswer[] }>;
    return result.data?.getDegreesByIds ?? [];
  }

  static async markDocumentAsSubmitted(documentIds: number[]): Promise<void> {
    await API.graphql({
      query: mutations.markDocumentAsSubmitted,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      variables: { documentIds },
    });
  }

  static async automatedFeedback(variables: AutomatedFeedbackQueryVariables) {
    const result = (await API.graphql({
      query: queries.automatedFeedback,
      variables: variables,
    })) as GraphQLResult<{ automatedFeedback: AutomatedFeedbackResponse }>;

    return result.data?.automatedFeedback;
  }

  static async disabledServicePortal(
    variables: DisabledServicePortalQueryVariables
  ) {
    const result = (await API.graphql({
      query: queries.disabledServicePortal,
      variables,
    })) as GraphQLResult<DisabledServicePortalQuery>;

    return result.data?.disabledServicePortal;
  }

  static async getTimeBufferType(variables: GetTimeBufferTypeQueryVariables) {
    const result = (await API.graphql({
      query: queries.getTimeBufferType,
      variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GraphQLResult<GetTimeBufferTypeQuery>;

    return result.data?.getTimeBufferType;
  }
}

export default ApiClient;
