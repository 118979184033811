import type { NextComponentType, NextPageContext } from 'next';
import type { AppProps } from 'next/app';

import {
  UsercentricsScript,
  UsercentricsProvider,
} from '@s-group/react-usercentrics';
import { Amplify } from 'aws-amplify';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ThemeProvider } from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import pkg from '../../package.json';
import '../../public/dateInput.css';
import '../../public/index.css';
import '../../public/react-datepicker.css';
import '../../public/toastify.css';
import awsconfig from '../aws-exports';
import GlobalStyle from '../components/elements/Layout/GlobalStyle';
import CurrentUserProvider from '../providers/CurrentUserProvider';

import theme from '$/assets/theme';
import NotSupportedBrowserNotification from '$/components/blocks/NotSupportedBrowser';
import AdblockDetector from '$/providers/AdblockDetector';
import { useGoogleTagManager } from '$/utils/googleTagManager';

import '../../public/dateInput.css';
import '../../public/index.css';
import '../../public/react-datepicker.css';
import '../../public/toastify.css';

Amplify.configure({
  ...awsconfig,
  ssr: true,
});

interface NewDeubiAppProps extends AppProps {
  Component: NextComponentType<NextPageContext, any, {}> & {
    requireAuth: boolean;
  };
}

const queryClient = new QueryClient();

const DynamicTrackingProvider = dynamic(
  () => import('$/providers/TrackingProvider'),
  {
    loading: () => <></>,
    ssr: false,
  }
);

const App = ({ Component, pageProps }: NewDeubiAppProps) => {
  useEffect(() => {
    console.info('version:', pkg.version);
  }, []);
  useGoogleTagManager();

  const lDClient = useLDClient();

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        {process.env.NODE_ENV === 'production' && (
          <meta
            name="facebook-domain-verification"
            content="a2fzxft5yhkh5d497iuumsl0u17q5j"
          />
        )}
      </Head>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          {process.env.NEXT_PUBLIC_IS_PRODUCTION === 'production' && (
            <UsercentricsScript settingsId="LE0Hh_gRK" />
          )}
          <UsercentricsProvider windowEventName="ucEvent">
            {lDClient && (
              <CurrentUserProvider>
                <DynamicTrackingProvider />
                <AdblockDetector />
                <NotSupportedBrowserNotification />
                <Component {...pageProps} />
              </CurrentUserProvider>
            )}
          </UsercentricsProvider>
        </QueryClientProvider>
        <GlobalStyle />
      </ThemeProvider>
    </>
  );
};

export default withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LD_CLIENT_ID as string,
  user: {
    anonymous: true,
  },
  options: {
    bootstrap: 'localStorage',
  },
})(
  // ignore due to TS incompatibility between LD and Next.js
  // @ts-ignore
  App
);
