import React, { useState } from 'react';
import { useEffect } from 'react';
import { useMutation } from 'react-query';

import ApiClient from '$/ApiClient';
import useCurrentUser from '$/hooks/useCurrentUser';

const AdblockDetector = () => {
  const { authenticated } = useCurrentUser();
  const [isAdblockDetected, setIsAdblockDetected] = useState<boolean | null>(
    null
  );
  const { mutateAsync } = useMutation(ApiClient.setAdblockDetection);

  useEffect(() => {
    if (isAdblockDetected !== null) {
      mutateAsync({
        data: { value: isAdblockDetected },
      });
    }
  }, [isAdblockDetected, mutateAsync]);

  useEffect(() => {
    async function detectAdBlock() {
      const adUrl = `js/prebid.js`;
      try {
        await fetch(adUrl).catch((err) => {
          setIsAdblockDetected(true);
        });
        setIsAdblockDetected(false);
      } catch (e) {
        setIsAdblockDetected(true);
      }
    }
    if (authenticated) {
      detectAdBlock();
    }
  }, [authenticated]);
  return <></>;
};

export default AdblockDetector;
