import styled from 'styled-components';

const Description = styled('div')`
  grid-area: description;
  font-weight: 300;
  font-size: 13px;
`;

Description.defaultProps = {
  className: 'toast-description',
};

export default Description;
