import type { colorStates } from './_colors';

import colorVariants from './_colorVariants';
import answer from './answer';
import autoCompleteAnswer from './autoCompleteAnswer';
import menu from './menu';
import menuItem from './menuItem';
import primary from './primary';
import secondary from './secondary';
import secondaryAlt from './secondaryAlt';
import text from './text';
import transparent from './transparent';
import transparentId from './transparentId';
import trueTransparent from './trueTransparent';

export interface IVariantProps {
  disabled?: boolean;
  color: colorStates;
  selected?: boolean;
}

export type TPossibleVariants =
  | 'primary'
  | 'secondary'
  | 'secondaryAlt'
  | 'text'
  | 'answer'
  | 'transparent'
  | 'menu'
  | 'transparentId'
  | 'menuItem'
  | 'trueTransparent'
  | 'autoCompleteAnswer';

const variants = (props: {
  colorStates?: colorStates;
  disabled?: boolean;
  selected?: boolean;
}): { [index in TPossibleVariants]: { button?: any; text?: any } } => ({
  primary: primary({
    color: {
      ...colorVariants.primary,
      ...props.colorStates,
    },
    disabled: props.disabled,
  }),
  secondary: secondary({
    color: {
      ...colorVariants.secondary,
      ...props.colorStates,
    },
    disabled: props.disabled,
  }),
  secondaryAlt: secondaryAlt({
    color: {
      ...colorVariants.secondaryAlt,
      ...props.colorStates,
    },
    disabled: props.disabled,
  }),
  text: text({
    color: {
      ...colorVariants.text,
      ...props.colorStates,
    },
    disabled: props.disabled,
  }),
  answer: answer({
    color: {
      ...colorVariants.secondaryAlt,
      ...props.colorStates,
    },
    disabled: props.disabled,
    selected: props.selected,
  }),
  transparent: transparent({
    color: {
      ...colorVariants.transparent,
      ...props.colorStates,
    },
  }),
  transparentId: transparentId({
    color: {
      ...colorVariants.transparent,
      ...props.colorStates,
    },
  }),
  menu: menu({
    color: {
      ...colorVariants.menu,
      ...props.colorStates,
    },
    selected: props.selected,
  }),
  trueTransparent: trueTransparent(),
  menuItem: menuItem({
    color: {
      ...colorVariants.menu,
      ...props.colorStates,
    },
  }),
  autoCompleteAnswer: autoCompleteAnswer({
    color: {
      ...colorVariants.secondaryAlt,
      ...props.colorStates,
    },
    disabled: props.disabled,
    selected: props.selected,
  }),
});

export const includeVariant = (
  props: { variant: string; colorStates?: colorStates },
  element: string
) => {
  return variants(props)[props.variant][element];
};

export default variants;
