import type { IVariantProps } from './index';

import { css } from 'styled-components';

const menuItem = ({}: IVariantProps) => ({
  button: css`
    display: flex;
    align-items: baseline;
    width: 100%;
    justify-content: flex-start;
  `,
  text: css`
    font-size: 18px;
    letter-spacing: 0.14px;
    color: ${(props) => props.theme.colors.grey7};
  `,
  icon: css`
    margin: 0 8px;
  `,
});

export default menuItem;
