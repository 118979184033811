const breakpoints = {
  xs: '375px',
  sm: '576px',
  md: '768px',
  lg: '1024px',
  xl: '1200px',
  xxl: '1600px',
};

export default breakpoints;
