import styled from 'styled-components';

const ToastWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    'icon title'
    'icon description'
    'icon description';
  grid-row-gap: 4px;
`;

ToastWrapper.defaultProps = {
  className: 'toast-wrapper',
};

export default ToastWrapper;
