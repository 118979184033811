import type { IVariantProps } from './index';

import { css } from 'styled-components';

import colors from './_colors';

const text = (props: IVariantProps) => ({
  button: css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    cursor: pointer;
    border-radius: 5px;

    ${props.disabled &&
    css`
      opacity: 0.4;
      cursor: default;
    `};
  `,
  text: css`
    color: ${colors[props.color.unset]};
    text-decoration: underline;
    transition: all 0.25s;

    &:hover {
      color: ${colors[props.color.hover]};
    }

    &:active {
      color: ${(props) => props.theme.colors.brandGreen};
    }

    ${props.disabled &&
    css`
      background: ${colors[props.color.disabled]};

      transition: none;

      &:hover {
        color: ${colors[props.color.unset]} !important;
      }

      &:active {
        color: ${colors[props.color.unset]} !important;
      }
    `}
  `,
});

export default text;
