import type { IVariantProps } from './index';

import { css } from 'styled-components';

import colors from './_colors';

const secondaryAlt = (props: IVariantProps) => ({
  button: css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.grey8};
    background: ${colors[props.color.unset]};
    transition: all 0.25s;

    &:hover {
      background: ${colors[props.color.hover]};
    }

    &:active {
      background: ${colors[props.color.active]};
    }

    ${props.disabled &&
    css`
      opacity: 0.7;
      background: ${colors[props.color.disabled]};
      cursor: not-allowed;

      transition: none;

      &:hover {
        background: ${colors[props.color.disabled]};
        opacity: 0.7;
      }

      &:active {
        background: ${colors[props.color.disabled]};
        opacity: 0.7;
      }
    `}
  `,
  text: css``,
});

export default secondaryAlt;
